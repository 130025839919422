let nsrePublicURL = "https://nfg.com/"

var atom = ''; 
var agentToken = '';

var groupsRoles = [

  {
    "id":"005",
    "type":"Employee",
    "name":"a10024",
  },
  {
    "id":"039",
    "type":"Employee",
    "name":"a10025",
  },
  {
    "id":"040",
    "type":"Employee",
    "name":"a10027",
  },
  {
    "id":"041",
    "type":"Employee",
    "name":"a10028",
  },
  {
    "id":"003",
    "type":"Employee",
    "name":"a10021",
  },
  {
    "id":"004",
    "type":"Employee",
    "name":"a10018",
  },
  {
    "id":"006",
    "type":"Employee",
    "name":"a10023",
  },

    {
    "id":"008",
    "type":"Employee",
    "name":"a10019",
  },
  {
    "id":"009",
    "type":"Employee",
    "name":"a10022",
  },
  {
    "id":"010",
    "type":"Employee",
    "name":"a10017",
  },
  {
    "id":"011",
    "type":"Employee",
    "name":"a10020",
  },

  {
    "id":"043",
    "type":"Employee",
    "name":"a10029",
  },

  {
    "id":"002",
    "type":"Agent",
    "name":"a10002",
  },

  {
    "id":"012",
    "type":"Agent",
    "name":"a10013",
  },

  {
    "id":"013",
    "type":"Agent",
    "name":"a10014",
  },
  {
    "id":"014",
    "type":"Agent",
    "name":"a10009",
  },
  {
    "id":"015",
    "type":"Agent",
    "name":"a100a10",
  },
  {
    "id":"016",
    "type":"Agent",
    "name":"a10011",
  },
  {
    "id":"017",
    "type":"Agent",
    "name":"a10012",
  },

   {
    "id":"019",
    "type":"Agent",
    "name":"a10008",
  },

    {
    "id":"023",
    "type":"Agent",
    "name":"a10015",
  },
  {
    "id":"024",
    "type":"Agent",
    "name":"a10016",
  },
  {
    "id":"025",
    "type":"Agent",
    "name":"a10006",
  },
  {
    "id":"026",
    "type":"Agent",
    "name":"a10001",
  },
  {
    "id":"027",
    "type":"Agent",
    "name":"a10007",
  },

    {
    "id":"028",
    "type":"Agent",
    "name":"u1001",
  },
  {
    "id":"029",
    "type":"Agent",
    "name":"u1002",
  },
  {
    "id":"030",
    "type":"CompanyAdmin",
    "name":"a10004",
  },

  {
    "id":"042",
    "type":"CompanyAdmin",
    "name":"a10028",
  },

  {
    "id":"031",
    "type":"CompanyRep",
    "name":"a10005",
  },
  {
    "id":"032",
    "type":"CompanyRep",
    "name":"a10006",
  },
  {
    "id":"033",
    "type":"CompanyRep",
    "name":"a10001",
  },
  {
    "id":"034",
    "type":"CompanyRep",
    "name":"a10016",
  },
  {
    "id":"035",
    "type":"CompanyRep",
    "name":"a10015",
  },
  {
    "id":"036",
    "type":"CompanyRep",
    "name":"a10007",
  },

  {
    "id":"037",
    "type":"Client",
    "name":"a10003",
  },

  {
    "id":"038",
    "type":"Client",
    "name":"a10007",
  }

];



var statementTypeNames = {

    'vulstmt':'Home Office Variable Universal Life Corrective Statement',
    'vulqtlystmt':'Variable Universal Life Quarterly Statement',
    'vulconf':'Variable Universal Life Transaction Confirmation',
    'vulanns':'Variable Universal Life Anniversary Statement',
    'vastmt':'Variable Annuity Old File Quarterly Statement',
    'vasemistmt':'Variable Annuity Semi-Annual Statement',
    'vaqtlystmt':'Variable Annuity Quarterly Statement',
    'vadlystmt':'Home Office Daily Corrective Statement',
    'vaconf':'Variable Annuity and Indexed Annuity Transaction Confirmation',
    'ulconf':'Universal Life Transaction Confirmation',
    'ulanns':'Universal Life Anniversary Statement',
    'pelanns':'Phoenix Estate Legacy Anniversary Statement',
    'fastmt':'Fixed Indexed Annuity Anniversary Statement',
    'faconf':'Fixed Annuity Transaction Confirmation',
    'surrenderletter': 'Surrender Letter',
    'annualstatement': 'Annual Statement',
    'soa' : "Quarterly/Account Statement",
    'ann' : "Annual Statement",
    'fc' : "Transaction Confirmation",
    'polpg' : "Contract/Policy Pages",
    'genltr' : "General Correspondence Letter",
    'lfflps' : "Lapse Notice",
    'bill' : "Billing Statement",
    'illus' : "'Illustration Request"

}

var statementTypeNames_old = {

    'vulstmt':'Home Office Variable Universal Life Corrective Statement',
    'vulqtlystmt':'Variable Universal Life Quarterly Statement',
    'vulconf':'Variable Universal Life Transaction Confirmation',
    'vulanns':'Variable Universal Life Anniversary Statement',
    'vastmt':'Variable Annuity Old File Quarterly Statement',
    'vasemistmt':'Variable Annuity Semi-Annual Statement',
    'vaqtlystmt':'Variable Annuity Quarterly Statement',
    'vadlystmt':'Home Office Daily Corrective Statement',
    'vaconf':'Variable Annuity and Indexed Annuity Transaction Confirmation',
    'ulconf':'Universal Life Transaction Confirmation',
    'ulanns':'Universal Life Anniversary Statement',
    'pelanns':'Phoenix Estate Legacy Anniversary Statement',
    'fastmt':'Fixed Indexed Annuity Anniversary Statement',
    'faconf':'Fixed Annuity Transaction Confirmation',
    'surrenderletter': 'Surrender Letter',
    'annualstatement': 'Annual Statement',

}


var userTypesURL = {

    'CompanyAdmin':'../company/index.html',

};


var actionTypesURL = {

    'eApp':'../quote/eapp.html',
    'foresight':'../quote/foresight.html'
}



var oktaToEnglish = {

    "Web_ProducerWithActiveAgreements" : 'Producer With Active Agreements',
    "Web_ProducerWithNoActiveAgreements" : 'Producer With No Active Agreements',
    "Web_ProducerServiceOnly" : 'Producer ServiceOnly',
    "Web_ProducerTBD" : 'Producer TBD',
    "Web_ProducerTerm" : 'Terminated Producer',
    "Web_ProducerTermForDeath" : 'Producer Term For Death',
    "Web_ProducerTermRestricted" : 'Producer Term Restricted',
    "Web_ProducerRegistedUncontracted" : 'Uncontracted Producer',
    "Web_SalesTools" : 'Sales Tools',
    "Web_WebReporting" : 'Web Reporting / Dashboard',
    "Web_CompStatements" : 'Compensation Statements',
    "Web_AccountValue" : 'Account Values and Documents',
    "Web_CompanyAdmin" :'Company Admin',
    "Web_CompanyRep" : 'Company Rep',
    "Web_MultiCompanyAdmin" : 'Multi-Company Admin',
    "IndividualClient" : 'Individual Client',
    'EntityClient' : 'Entity Client',


   }

var englishToOkta = {

    'Account Values and Documents' : 'Web_AccountValue',
    'Web Reporting / Dashboard' : 'Web_WebReporting', 
    'Compensation Statements' : 'Web_CompStatements',
    'Multi-Company Admin' : 'Web_MultiCompanyAdmin',
    'Sales Tools' : 'Web_SalesTools',
    'Company Rep' : 'Web_CompanyRep',
    'Company Admin' : 'Web_CompanyAdmin',
    'Individual Client' : 'IndividualClient',
    'Entity Client': 'EntityClient',
}


var oktaHelpdeskStatus = {

    'Disabled':'Deactivated',
    'Pending':'Email Unconfirmed',
    'Active':'Active',
    'Locked':'Locked',
    'Email Unconfirmed' : 'Email Unconfirmed',
    'Deactivated' : 'Deactivated'
}

var oktaAffiliateStatus = {

    'Disabled':'Deactivated',
    'Pending':'Email Unconfirmed',
    'Active':'Active',
    'Locked':'Locked',
    'Email Unconfirmed' : 'Email Unconfirmed',
    'Deactivated' : 'Deactivated'
}


var usStates = [

            { name: 'ALABAMA', abbreviation: 'AL'},
        { name: 'ALASKA', abbreviation: 'AK'},
        { name: 'ARIZONA', abbreviation: 'AZ'},
        { name: 'ARKANSAS', abbreviation: 'AR'},
        { name: 'CALIFORNIA', abbreviation: 'CA'},
        { name: 'COLORADO', abbreviation: 'CO'},
        { name: 'CONNECTICUT', abbreviation: 'CT'},
        { name: 'DELAWARE', abbreviation: 'DE'},
        { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC'},
        { name: 'FLORIDA', abbreviation: 'FL'},
        { name: 'GEORGIA', abbreviation: 'GA'},
        { name: 'HAWAII', abbreviation: 'HI'},
        { name: 'IDAHO', abbreviation: 'ID'},
        { name: 'ILLINOIS', abbreviation: 'IL'},
        { name: 'INDIANA', abbreviation: 'IN'},
        { name: 'IOWA', abbreviation: 'IA'},
        { name: 'KANSAS', abbreviation: 'KS'},
        { name: 'KENTUCKY', abbreviation: 'KY'},
        { name: 'LOUISIANA', abbreviation: 'LA'},
        { name: 'MAINE', abbreviation: 'ME'},
        { name: 'MARYLAND', abbreviation: 'MD'},
        { name: 'MASSACHUSETTS', abbreviation: 'MA'},
        { name: 'MICHIGAN', abbreviation: 'MI'},
        { name: 'MINNESOTA', abbreviation: 'MN'},
        { name: 'MISSISSIPPI', abbreviation: 'MS'},
        { name: 'MISSOURI', abbreviation: 'MO'},
        { name: 'MONTANA', abbreviation: 'MT'},
        { name: 'NEBRASKA', abbreviation: 'NE'},
        { name: 'NEVADA', abbreviation: 'NV'},
        { name: 'NEW HAMPSHIRE', abbreviation: 'NH'},
        { name: 'NEW JERSEY', abbreviation: 'NJ'},
        { name: 'NEW MEXICO', abbreviation: 'NM'},
        { name: 'NEW YORK', abbreviation: 'NY'},
        { name: 'NORTH CAROLINA', abbreviation: 'NC'},
        { name: 'NORTH DAKOTA', abbreviation: 'ND'},
        { name: 'OHIO', abbreviation: 'OH'},
        { name: 'OKLAHOMA', abbreviation: 'OK'},
        { name: 'OREGON', abbreviation: 'OR'},
        { name: 'PENNSYLVANIA', abbreviation: 'PA'},
        { name: 'RHODE ISLAND', abbreviation: 'RI'},
        { name: 'SOUTH CAROLINA', abbreviation: 'SC'},
        { name: 'SOUTH DAKOTA', abbreviation: 'SD'},
        { name: 'TENNESSEE', abbreviation: 'TN'},
        { name: 'TEXAS', abbreviation: 'TX'},
        { name: 'UTAH', abbreviation: 'UT'},
        { name: 'VERMONT', abbreviation: 'VT'},
        { name: 'VIRGINIA', abbreviation: 'VA'},
        { name: 'WASHINGTON', abbreviation: 'WA'},
        { name: 'WEST VIRGINIA', abbreviation: 'WV'},
        { name: 'WISCONSIN', abbreviation: 'WI'},
        { name: 'WYOMING', abbreviation: 'WY' }
  ];


  var States =
    {
      "Alabama": "Alabama",
      "Alaska": "Alaska",
      "Arizona": "Arizona",
      "Arkansas": "Arkansas",
      "California": "California",
      "Colorado": "Colorado",
      "Connecticut": "Connecticut",
      "Delaware": "Delaware",
      "District of Columbia": "District of Columbia",
      "Florida": "Florida",
      "Georgia": "Georgia",
      "Hawaii": "Hawaii",
      "Idaho": "Idaho",
      "Illinois": "Illinois",
      "Indiana": "Indiana",
      "Iowa": "Iowa",
      "Kansas": "Kansas",
      "Kentucky": "Kentucky",
      "Louisiana": "Louisiana",
      "Maine": "Maine",
      "Maryland": "Maryland",
      "Massachusetts": "Massachusetts",
      "Michigan": "Michigan",
      "Minnesota": "Minnesota",
      "Mississippi": "Mississippi",
      "Missouri": "Missouri",
      "Montana": "Montana",
      "Nebraska": "Nebraska",
      "Nevada": "Nevada",
      "New Hampshire": "New Hampshire",
      "New Jersey": "New Jersey",
      "New Mexico": "New Mexico",
      "New York": "New York",
      "North Carolina": "North Carolina",
      "North Dakota": "North Dakota",
      "Ohio": "Ohio",
      "Oklahoma": "Oklahoma",
      "Oregon": "Oregon",
      "Pennsylvania": "Pennsylvania",
      "Rhode Island": "Rhode Island",
      "South Carolina": "South Carolina",
      "South Dakota": "South Dakota",
      "Tennessee": "Tennessee",
      "Texas": "Texas",
      "Utah": "Utah",
      "Vermont": "Vermont",
      "Virginia": "Virginia",
      "Washington": "Washington",
      "West Virginia": "West Virginia",
      "Wisconsin": "Wisconsin",
      "Wyoming": "Wyoming"
    };

var illustrationAvailabiltyProducts =
    {
    "ESTATEEDGE": "VUL",
    "FLEXEDGE SUCCESS": "VUL",
    "JOINTEDGE": "VUL",
    "PHOENIX JOINT EDGE VUL": "VUL",
    "PHOENIX ACCUMULATOR": "UL",
    "PHOENIX ACCUMULATOR II": "UL",
    "PHOENIX ACCUMULATOR III": "UL",
    "PHOENIX ACCUMULATOR IV": "UL",
    "PHOENIX BENEFIT CHOICE VUL": "VUL",
    "ESTATE LEGACY": "UL",
    "ESTATE LEGACY II": "UL",
    "ESTATE LEGACY III": "UL",
    "ESTATE LEGACY 4": "UL",
    "PHOENIX EDGE SVUL": "VUL",
    "PHOENIX EDGE VUL": "VUL",
    "PHOENIX GENERATIONS": "UL",
    "INDIVIDUAL EDGE": "VUL",
    "PHOENIX PROTECTOR": "UL",
    "PHOENIX PROTECTOR II": "UL",
    "PHOENIX EXPRESS VARIABLE UNIVERSAL LIFE": "VUL",
    "SURVIVORSHIP UL WITH GUARANTEE": "UL",
    "PHOENIX UL WITH GUARANTEE": "UL",
    "PHOENIX INDEXED UL": "UL",
    "JOINTEDGE SERIES A": "VUL"
};




var masterActionMenu = {


      'Company' : {

    'PPLUS': {

      'Active': {

        'Role' : {

          'a10024' : {

            'actions': []
          },
          'a10018' : {

            'actions': []
          },

          'a10019' : {

            'actions': ['addAdmin']
          }


        }
      }
    },

    'LINKED': {

      'Active': {


        'a10004':{

          'a10024' : {

            'actions': ['editAgent', 'disableAgent', 'resetMFA']
          },
          'a10018' : {

            'actions': ['editAgent', 'disableAgent', 'deleteAgent', 'resetMFA']
          },

          'a10019' : {

            'actions': ['editAgent', 'disableAgent', 'addMultiCompany']
          }

        },

        'a10004b':{

          'a10024' : {

            'actions': ['editAgent', 'disableAgent', 'resetMFA']
          },
          'a10018' : {

            'actions': ['editAgent', 'disableAgent', 'deleteAgent', 'resetMFA']
          },

          'a10019' : {

            'actions': ['editAgent', 'disableAgent', 'removeMultiCompany']
          }

        },


              'Role' : {

          'a10024' : {

            'actions': ['editAgent', 'disableAgent', 'resetMFA']
          },
          'a10018' : {

            'actions': ['editAgent', 'disableAgent', 'deleteAgent', 'resetMFA']
          },

          'a10019' : {

            'actions': ['editAgent', 'disableAgent']
          }


        }
      },

      'Deactivated': {

        'Role' : {

          'a10024' : {

            'actions': ['editAgent', 'enableAgent']
          },
          'a10018' : {

            'actions': ['editAgent', 'enableAgent', 'deleteAgent']
          },

          'a10019' : {

            'actions': ['editAgent', 'enableAgent']
          }


        }
      },

      'Locked': {

        'Role' : {

          'a10024' : {

            'actions': ['editAgent', 'disableAgent']
          },
          'a10018' : {

            'actions': ['editAgent', 'disableAgent', 'deleteAgent']
          },

          'a10019' : {

            'actions': ['editAgent', 'disableAgent']
          }


        }
      },


      'Email Unconfirmed': {

        'Role' : {

          'a10024' : {

            'actions': ['editAgent', 'deleteAgent', 'resendInvite'] 
          },
          'a10018' : {

            'actions': ['editAgent', 'deleteAgent', 'resendInvite']
          },

          'a10019' : {

            'actions': ['editAgent', 'resendInvite']
          }


        }
      }

    }

  },


  'Agent' : {

        'LINKED': {

      'Active': {

        'Role' : {

          'a10024' : {

            'actions': ['editAgent', 'disableAgent', 'resetMFA']
          },
          'a10018' : {

            'actions': ['editAgent', 'disableAgent', 'deleteAgent', 'resetMFA']
          },

          'a10019' : {

            'actions': []
          }


        }
      },

      'Deactivated': {

        'Role' : {

          'a10024' : {

            'actions': ['editAgent', 'enableAgent']
          },
          'a10018' : {

            'actions': ['editAgent', 'enableAgent', 'deleteAgent']
          },

          'a10019' : {

            'actions': []
          }


        }
      },

      'Locked': {

        'Role' : {

          'a10024' : {

            'actions': ['editAgent', 'disableAgent'] 
          },
          'a10018' : { 

            'actions': ['editAgent', 'deleteAgent', 'disableAgent']
          },

          'a10019' : {

            'actions': []
          }


        }
      },




          },

    'WEBONLY': {

      'Active': {

        'Role' : {

          'a10024' : {

            'actions': ['editAgent', 'disableAgent', 'resetMFA']
          },
          'a10018' : {

            'actions': ['editAgent', 'disableAgent', 'deleteAgent', 'resetMFA']
          },

          'a10019' : {

            'actions': []
          }


        }
      },

      'Deactivated': {

        'Role' : {

          'a10024' : {

            'actions': ['editAgent', 'enableAgent']
          },
          'a10018' : {

            'actions': ['editAgent', 'enableAgent', 'deleteAgent']
          },

          'a10019' : {

            'actions': []
          }


        }
      },

      'Locked': {

        'Role' : {

          'a10024' : {

            'actions': ['editAgent', 'disableAgent']
          },
          'a10018' : {

            'actions': ['editAgent', 'deleteAgent', 'disableAgent']
          },

          'a10019' : {

            'actions': []
          }


        }
      },


      'Email Unconfirmed': {

        'Role' : {

          'a10024' : {

            'actions': ['editAgent', 'deleteAgent', 'resendInvite'] 
          },
          'a10018' : {

            'actions': ['editAgent', 'deleteAgent', 'resendInvite'] 
          },

          'a10019' : {

            'actions': []
          }


        }
      },

    },

    'PPLUS': {

      'Active': {

        'Role' : {

          'a10024' : {

            'actions': []
          },
          'a10018' : {

            'actions': []
          },

          'a10019' : {

            'actions': []
          }


        }
      },


      },

  },

  'Client' : {

    'LINKED': {

      'Active': {

        'Role' : {

          'a10024' : {

            'actions': ['editClient', 'disableClient', 'resetMFA']
          },

          'a10018' : {

            'actions': ['editClient', 'disableClient', 'deleteClient', 'resetMFA']
          },


        }
      },

      'Deactivated': {

        'Role' : {

          'a10024' : {

            'actions': ['editClient', 'enableClient']
          },
          'a10018' : {

            'actions': ['editClient', 'enableClient', 'deleteClient']
          },


        }
      },

      'Locked': {

        'Role' : {

          'a10024' : {

            'actions': ['editClient', 'enableClient']
          },
          'a10018' : {

            'actions': ['editClient', 'enableClient', 'deleteClient']
          },

        }
      },


      'Email Unconfirmed': {

        'Role' : {

          'a10024' : {

            'actions': ['editClient', 'enableClient', 'resendInvite'] 
          },
          'a10018' : {

            'actions': ['editClient', 'deleteClient', 'resendInvite']
          },

        }
      }

    },


    'WEBONLY': {

      'Active': {

        'Role' : {

          'a10024' : {

            'actions': ['editClient', 'disableClient', 'resetMFA']
          },
          'a10018' : {

            'actions': ['editClient', 'disableClient', 'deleteClient', 'resetMFA']
          }

        }
      },

      'Deactivated': {

        'Role' : {

          'a10024' : {

            'actions': ['editClient', 'enableClient']
          },
          'a10018' : {

            'actions': ['editClient', 'enableClient', 'deleteClient']
          }


                }
      },

      'Locked': {

        'Role' : {

          'a10024' : {

            'actions': ['editClient', 'disableClient']
          },
          'a10018' : {

            'actions': ['editClient', 'deleteClient', 'disableClient']
          }

        }
      },


      'Email Unconfirmed': {

        'Role' : {

          'a10024' : {

            'actions': ['editClient', 'deleteClient', 'resendInvite'] 
          },
          'a10018' : {

            'actions': ['editClient', 'deleteClient', 'resendInvite'] 
          }

        }
      }

    },

    'CLIENT FILE': {

      'Active': {

        'Role' : {

          'a10024' : {

            'actions': []
          },
          'a10018' : {

            'actions': []
          }

        }
      }
    }

  }

}





if (!Array.prototype.includes) {
  Object.defineProperty(Array.prototype, "includes", {
    enumerable: false,
    value: function(obj) {
        var newArr = this.filter(function(el) {
          return el == obj;
        });
        return newArr.length > 0;
      }
  });
}


