'use strict';

var oxyThemeData = {
        navbarHeight : 100,
        navbarScrolled : 60,
        navbarScrolledPoint : 20,
        menuClose : 'off',
        scrollFinishedMessage : 'No more items to load.',
        hoverMenu : {
            hoverActive : true,
            hoverDelay : 1,
            hoverFadeDelay : 200
        },
        siteLoader: 'on'
    }



function oxyThemeScripts($) {

    if(!(/Android|iPhone|iPad|iPod|BlackBerry|Windows Phone/i).test(navigator.userAgent || navigator.vendor || window.opera)){

    } else {
        $('body:not([class*="oxy-agent-"])').addClass('oxy-agent-');
        if((/iPhone/i).test(navigator.userAgent || navigator.vendor || window.opera)){
            $('body').not('.oxy-agent-iphone').addClass('oxy-agent-iphone');
        }
        if((/iPad/i).test(navigator.userAgent || navigator.vendor || window.opera)){
            $('body').not('.oxy-agent-ipad').addClass('oxy-agent-ipad');
        }
        if((/iPod/i).test(navigator.userAgent || navigator.vendor || window.opera)){
          $('body').not('.oxy-agent-ipod').addClass('oxy-agent-ipod');
        }
        if((/Android/i).test(navigator.userAgent || navigator.vendor || window.opera)){
          $('body').not('.oxy-agent-android').addClass('oxy-agent-android');
        }
        if((/BlackBerry/i).test(navigator.userAgent || navigator.vendor || window.opera)){
          $('body').not('.oxy-agent-bb').addClass('oxy-agent-bb');
        }
        if((/Windows Phone/i).test(navigator.userAgent || navigator.vendor || window.opera)){
          $('body').not('.oxy-agent-wp').addClass('oxy-agent-wp');
        }
    }

    var isMobile = !$('body:not([class*="oxy-agent-"])').length;



    function onScrollInit( items, trigger ) {
        items.each( function() {
            var osElement = $(this),
                osAnimationClass = osElement.attr('data-os-animation'),
                osAnimationDelay = osElement.attr('data-os-animation-delay');

            osElement.css({
                '-webkit-animation-delay':  osAnimationDelay,
                '-moz-animation-delay':     osAnimationDelay,
                'animation-delay':          osAnimationDelay
            });

            var osTrigger = ( trigger ) ? trigger : osElement;

            osTrigger.waypoint(function() {
                osElement.addClass('animated').addClass(osAnimationClass);
            },{
                triggerOnce: true,
                offset: '90%'
            });
        });
    }



    $('.countdown').each(function() {
        var countdown = $(this);
        var date = countdown.attr('data-date' );
        countdown.countdown( date ).on('update.countdown', function(event) {
            $(this).find('.counter-days').html( event.offset.totalDays );
            $(this).find('.counter-hours').html( event.offset.hours );
            $(this).find('.counter-minutes').html( event.offset.minutes );
            $(this).find('.counter-seconds').html( event.offset.seconds );
        });
    });


    $('.counter').each(function() {
        var $counter = $(this);
        var $odometer = $counter.find('.odometer-counter');
        if($odometer.length > 0 ) {
            var od = new Odometer({
                el: $odometer[0],
                value: $odometer.text(),
                format: $counter.attr('data-format')
            });
            $counter.waypoint(function() {
                window.setTimeout(function() {
                    $odometer.html( $counter.attr( 'data-count' ) );
                }, 500);
            },{
                triggerOnce: true,
                offset: 'bottom-in-view'
            });
        }
    });




    $('select').not('.country_to_state, #billing_state, #shipping_state, #calc_shipping_state').wrap('<div class="select-wrap">');





    function sectionWaypointOffset( $section, direction, menu ) {
        var offset = direction === 'up' ? -($section.height() / 2) : 0;
        var menuHeight = parseInt(oxyThemeData.navbarHeight);
        if( menu.length && menu.hasClass('navbar-sticky') && menu.hasClass('navbar-scrolled') ){
            menuHeight = parseInt(oxyThemeData.navbarScrolled);
        }
        var sectionOffset = $section.offset().top;
        var menuOffset = menu.length? menu.position().top : 0;
        if( menu.length && menu.hasClass('navbar-sticky') && (  (menuOffset + menuHeight)  <= sectionOffset ) && !isMobile ){
            offset += menuHeight;
        }
        return offset;
    }

    function sectionWaypointHandler( scrollMenuItems, menuItems, bulletNav, section ) {
        if( scrollMenuItems.length ) {
            menuItems.parent().removeClass('active current-menu-item').end().filter('[href$="' + section.id + '"]').parent().addClass('active current-menu-item');
        }
        if( bulletNav.length ) {
            bulletNav.find('a').removeClass('active').filter('[href$="' + section.id + '"]').addClass('active');
        }
    }





    var menu = $('#masthead');
    var menuInitOffset = $('#masthead').position();
    menuInitOffset = menuInitOffset === undefined ? 0 : menuInitOffset.top;

    var menuItems = $('.menu').find('a');
    var scrollMenuItems = menuItems.map(function(){
        var item = this.hash;
        if (item && $(item).length ) {
            return item;
        }
    });

    var bulletNav = $('article .bullet-nav');
    if( bulletNav.length || scrollMenuItems.length ) {
        var sections = [];
        $('article').find('section').each( function() {
            if( this.id ) {
                sections.push(this);
            }
        });
        if( sections.length ) {
            if( scrollMenuItems.length ) {
                menuItems.parent().removeClass('active current-menu-item');
            }
            $.each( sections, function( index, section) {
                var $section = $(section);
                if( bulletNav.length ){
                    var list = bulletNav.find('ul');
                    var tooltipAtts = '';
                    if( 'on' === bulletNav.attr( 'data-show-tooltips' ) ) {
                         tooltipAtts = 'data-toggle="tooltip" data-placement="left" title="' + $section.attr('data-label') + '"';
                    }
                    list.append('<li><a href="#' + section.id + '" ' + tooltipAtts + '>' + section.id + '</a>');
                }
                $section.waypoint({
                    offset: function() {
                        return sectionWaypointOffset( $section, 'up', menu );
                    },
                    handler: function(direction) {
                        if( 'up' === direction ) {
                            sectionWaypointHandler( scrollMenuItems, menuItems, bulletNav, section );
                        }
                    }
                });
                $section.waypoint({
                    offset: function() {
                        return sectionWaypointOffset( $section, 'down', menu );
                    },
                    handler: function(direction) {
                        if( 'down' === direction ) {
                            sectionWaypointHandler( scrollMenuItems, menuItems, bulletNav, section );
                        }
                    }
                });
            });
            if( bulletNav.length ){
                bulletNav.show();
                bulletNav.css('top', ( $(window).height() - bulletNav.height() )/ 2 );
            }
        }
    }




    function scrollToHash(hash) {
        var $target = $(hash);
        var offset = 0;

        var targetOffset = $target.offset().top;
        var menuHeight = parseInt(oxyThemeData.navbarHeight);

        if(menu !== undefined && menu.hasClass('navbar-sticky') && ( menuInitOffset + menuHeight <= targetOffset) && !isMobile){
            var scrollPoint = parseInt( oxyThemeData.navbarScrolledPoint );
            var navHeightBeforeScrollPoint = parseInt( oxyThemeData.navbarHeight );
            var navHeightAfterScrollPoint = parseInt( oxyThemeData.navbarScrolled );

            offset = scrollPoint <= targetOffset ? navHeightAfterScrollPoint : navHeightBeforeScrollPoint;
            offset += menu.hasClass('header-logo-center-menu-below') || menu.hasClass('header-logo-left-menu-below') || menu.hasClass('header-logo-right-menu-below') ? menu.find('.nav-container').outerHeight() : 0;
        }

        $.scrollTo( $target, 800, {
            axis: 'y',
            offset: -offset + 1
        });
    }

    $('.menu a, a.scroll-to-id, .bullet-nav a').on('click', function(e) {
        if(this.hash) {
          scrollToHash(this.hash);
          e.preventDefault();
        }
    });

    $(window).on('load', function () {
      if(window.location.hash) {
        scrollToHash(window.location.hash);
      }
    });



    var header = $('body').find('.navbar-sticky');
    if(header.length > 0) {
        var sticky = new Waypoint.Sticky({
            element: header[0],
            stuckClass: 'navbar-stuck'
        });
    }

    $('#masthead').parents('.section').css({
        'overflow': 'visible',
        'position': 'relative',
        'z-index': '1100'
    });

    var menuContainer = $('#masthead.navbar-sticky');
    if(menuContainer.length && menuContainer.hasClass('navbar-sticky')){
        var menuOffset =  menuContainer.position().top;
        $('body').waypoint({
            offset: -( parseInt( oxyThemeData.navbarScrolledPoint ) + menuOffset ),
            handler: function(direction) {
                menuContainer.toggleClass('navbar-scrolled');
                var prefix = 'swatch-';
                var classes = menuContainer[0].className.split(' ').filter(function(c) {
                    return c.lastIndexOf(prefix, 0) !== 0;
                });
                menuContainer[0].className = classes.join(' ');
                menuContainer.one('MSTransitionEnd webkitTransitionEnd oTransitionEnd transitionend', function(){
                    if( !menuContainer.hasClass( 'refreshing' ) ) {
                        Waypoint.refreshAll();
                    }
                    menuContainer.toggleClass('refreshing');
                });
            }
        });
    }

    $('body').waypoint({
        offset: -200,
        handler: function(direction) {
            if(direction === 'down'){
                $('.go-top').css('bottom', '12px').css('opacity', '1');
            }
            else{
                $('.go-top').css('bottom', '-44px').css('opacity', '0');
            }
        }
    });



    function initialisePageScrollAnimations() {
        onScrollInit( $('.os-animation') );
        onScrollInit( $( '.staff-os-animation' ), $('.staff-list-container') );
        onScrollInit( $( '.recent-simple-os-animation' ), $('.recent-simple-os-container') );
    }



    if(oxyThemeData.siteLoader === 'on') {
        Pace.on('done', function() {
            setTimeout(function() {
                initialisePageScrollAnimations();
            }, 500);
        });
    }
    else {
        initialisePageScrollAnimations();
    }

    $('.go-top').click(function(event) {
        event.preventDefault();
        $('html, body').animate({scrollTop: 0}, 300);
    });


    $('[data-toggle="tooltip"]').tooltip();


    $('.carousel').carousel({
        interval: 7000
    });

    $('.accordion-body').on('hide', function () {
        $(this).parent('.accordion-group').find('.accordion-toggle').addClass('collapsed');
    });

    $('.menu').on('show.bs.collapse', function () {
        if($('body').hasClass('slide-menu')) {
            $('body').addClass('slide-on');
        }
        $('.navbar-toggle').removeClass('collapsed');
    });
    $('.menu').on('hide.bs.collapse', function () {
        if($('body').hasClass('slide-menu')) {
            $('body').removeClass('slide-on');
        }
        $('.navbar-toggle').addClass('collapsed');
    });

    if(oxyThemeData.menuClose === 'on') {
        $('body.slide-menu #content').on('click', function() {
            if($('body.slide-menu').hasClass('slide-on')) {
                $('#navbar-slide').collapse('hide');
            }
        });
    }







    $('[data-iconcolor]').each(function(){
        var element         = $(this);
        var originalColor  = $(element).css('background-color');
        var originalTextColor  = $(element).find('i').css('color');
        element.on('mouseenter', function(){
            element.css('background-color' , element.attr('data-iconcolor'));
            element.find('i').css('color' , '#ffffff');
            if (element.parents('.social-icons').hasClass('social-simple')) {
                element.find('i').css('color' , element.attr('data-iconcolor'));
            }
        });
        element.on('mouseleave', function(){
            element.css('background-color' ,originalColor);
            element.find('i').css('color' , originalTextColor);
            if (element.parents('.social-icons').hasClass('social-simple')) {
                element.find('i').css('color' , originalTextColor);
            }
        });

    });



    if (oxyThemeData.hoverMenu.hoverActive === true) {
        if($('body:not([class*="oxy-agent"])').length) {
            $('.navbar .dropdown').hover(function() {
                $(this).find('.dropdown-menu').first().stop(true, true).delay(oxyThemeData.hoverMenu.hoverDelay).fadeIn(parseInt(oxyThemeData.hoverMenu.hoverFadeDelay));
            }, function() {
                $(this).find('.dropdown-menu').first().stop(true, true).delay(oxyThemeData.hoverMenu.hoverDelay).fadeOut(parseInt(oxyThemeData.hoverMenu.hoverFadeDelay));
            });
        }

        $('#masthead .nav li.dropdown a').on('click', function(){
            var $dropdown = $(this);
            if($dropdown.parent().hasClass('open') && ($dropdown.attr('data-link') !== undefined) ) {
                window.location = $dropdown.attr('data-link');
            }
        });
    }


 }


jQuery(document).ready(function($) {

    if(window.self !== window.top){
        $(window).on('load', function() {
            oxyThemeScripts($);
        });

    } else {
        oxyThemeScripts($);
    }

    dynaMenu();

});








