var errorCodes = {


      'OK1001' : function(){
          var o = {
          'title':'Error Calling OKTA',
          'desc':'LAMBDA - *',
          'text':'Error Calling OKTA',
        };

        processing('hide');
        syserror('show')(o.text);
      },

      'OK1004' : function(){
          var o = {
          'title':'Error Calling OKTA during callDelete',
          'desc':'LAMBDA - *',
          'text':'Error Calling OKTA during Delete',
        };

        processing('hide');
        syserror('show')(o.text);
      },

      'SE1001':function(){

          var o = {
              'title':'User not Found',
              'desc':'LAMBDA - AgentSignIn-dev',
              'text':'Invalid Authentication',
          };

          processing('hide');
          syserror('show')(o.text);

      },
      'SE1002':function(){

          var o = {
              'title':'Invalid Credentials',
              'desc':'LAMBDA - AgentSignIn-dev',
              'text':'Incorrect username or password',
              'forgot':'Incorrect username or password. Click on the Forgot Password link to reset your password.',

          };


          if(checkNull(sessionValue('badLoginTime'))){

              var now = new Date().getMilliseconds();
              var diff = now - parseInt(sessionValue('badLoginTime'))

              if(diff >= 360000){ 

                  sessionValue('badLoginTime', null)
                  sessionValue('badLogin', null)
              }
          }

          if(checkNull(sessionValue('badLogin') == null)){

              sessionValue('badLogin', 1);

              var now = new Date().getMilliseconds();

              sessionValue('badLoginTime', now);

          } else {

              sessionValue('badLogin', parseInt(sessionValue('badLogin'))+1);

          }

          if(parseInt(sessionValue('badLogin')) <= 4){

              processing('hide');
              syserror('show')(o.text);

          } else {

              processing('hide');
              syserror('show')(o.forgot);
          }

      },
      'SE1003':function(){

        var o = { 
          'title':'Email already exists',
          'desc':'LAMBDA - AgentSignUp-dev',
          'text':'This Email address is already registered. Please enter a different email address.',
        };

        processing('hide');
        syserror('show')(o.text);

      },
      'SE1004':function(){

          var o = {
              'title':'User not Confirmed',
              'desc':'LAMBDA - AgentSignIn',
              'text':'User not Confirmed',
          };

          if(atom.page){

              atom.$set(atom, 'page', 'send-code');

              processing('hide');

          } else {

              syserror('show')(o.text);
          }


      },
      'SE1005':function(){

          var o = {
              'title':'Invalid Request',
              'desc':'LAMBDA - AgentSignIn-dev',
              'text':'Invalid Request',
          };

          processing('hide');
          syserror('show')(o.text);

      },
      'SE1006':function(){

          var o = {
              'title':'Invalid confirmation code',
              'desc':'LAMBDA - requestResendConfirmationCode',
              'text':'Please enter the correct authorization code. If you have not received one, click on Resend code.',
          };

          syserror('show')(o.text);

      },
      'SE1007':function(){

          var o = {
              'title':'Affiliate user first login',
              'desc':'LAMBDA - AgentSignIn',
              'text':'User must change pasword',
          };

          if(atom.page){

              atom.$set(atom, 'page', 'signin-new');
              atom.$set(atom, 'pageTitle', 'AGENT REGISTRATION');

              processing('hide');

          } else {

              syserror('show')(o.text);
          }


      },

    'SE1009':function(){

        var o = {
            'title':'Entered wrong verification code',
            'desc':'LAMBDA - VerifySMS-test',
            'text':'Your token doesn’t match our records.  Please try again.',
        };

        domVisWithText('#verifyError','show',o.text);

    },

    'SE1011':function(){

        var o = {
            'title':'Error listing cognito',
            'desc':'LAMBDA - AgentSignIn-dev',
            'text':'Invalid Authentication',
        };

        processing('hide');
        syserror('show')(o.text);

    },
      'SE1012':function(){

          var o = {
              'title':'No match in records',
              'desc':'LAMBDA - ClientSignUp-dev',
              'text':'We cannot complete account set up with the information entered. Please review and try again, or contact us for assistance at 1-800-349-9267.',
          };
          processing('hide');
          syserror('show')(o.text);

      },

           'SE1013':function(){

          var o = {
              'title':'SSN already registered',
              'desc':'LAMBDA - ?',
              'text':'Sorry, this SSN is already registered.',
          };

          processing('hide');
          syserror('show')(o.text);

      },
      'SE1014':function(){

          var o = {
              'title':'Failed to read data from database',
              'desc':'LAMBDA - ClientSignUp-dev',
              'text':'Failed to read data from database.',
          };
          processing('hide');
          syserror('show')(o.text);

      },
      'SE1015':function(){

          var o = {
              'title':'Failed to create user in Cognito',
              'desc':'LAMBDA - ClientSignUp-dev',
              'text':'We are unable to register this account with the information you have provided. Please review the information and try again or contact us at 1-800-349-9267.',
          };
          processing('hide');
          syserror('show')(o.text);

      },
      'SE1016':function(){

          var o = {
              'title':'User Not added to registration Table',
              'desc':'LAMBDA - ClientSignUp-dev',
              'text':'User Not added to registration Table.',
          };
          processing('hide');
          syserror('show')(o.text);

      },
      'SE1017':function(){

          var o = {
              'title':'User Not Found',
              'desc':'LAMBDA - ClientSignIn-dev',
              'text':'User Not Found.',
          };
          processing('hide');
          syserror('show')(o.text);

      },
      'SE1018':function(){

          var o = {
              'title':'Error User exceeded password attempts',
              'desc':'LAMBDA -ClientSignIn-dev',
              'text':'Password attempts exceeded. Click on the Forgot Password link and reset your password.',
          };

          processing('hide');
          syserror('show')(o.text);

      },
      'SE1019':function(){

          var o = {
              'title':'Not Authorized User',
              'desc':'LAMBDA -ClientSignIn-dev',
              'text':'User is not Authorized.',
          };

          processing('hide');
          syserror('show')(o.text);

      },
      'SE1020':function(){

          var o = {
              'title':'Error User exceeded password attempts',
              'desc':'LAMBDA - AgentSignIn-dev',
              'text':'Password attempts exceeded. Click on the Forgot Password link and reset your password.',
          };

          processing('hide');
          syserror('show')(o.text);

      },
      'SE1021':function(){

          var o = {
              'title':'Failed to authenticate the user',
              'desc':'LAMBDA -ClientSignIn-dev',
              'text':'The account associated with this email address is not active. Please contact us at 1-800-541-0171 to so we may assist you.',
          };

          processing('hide');
          syserror('show')(o.text);

      },

      'CS1001':function(){

        var o = { 
          'title':'failed to read from agent database',
          'desc':'LAMBDA - ?',
          'text':'failed to read from agent database.',
        };

        processing('hide');
        syserror('show')(o.text);

      },
      'CS1002':function(){

        var o = { 
          'title':'No match, Please check the number and try again',
          'desc':'LAMBDA - ListClientStatement',
          'text':'No results found. Please try your search again.',
        };

        processing('hide');
        syserror('show')(o.text);

      },

      'CS1003':function(){

        var o = { 
          'title':'Please contact your Administrative Manager for access authorization',
          'desc':'LAMBDA - ListClientStatement',
          'text':'Please contact your Administrative Manager for access authorization',
        };

        processing('hide');
        syserror('show')(o.text);

      },

          'CS1011':function(){

        var o = { 
          'title':'You are not allowed to view this Policy',
          'desc':'LAMBDA - ListClientStatement',
          'text':'You do not have access to view information on this policy. If you need assistance, please contact us at 1-800-349-9267.',
        };

        processing('hide');
        syserror('show')(o.text);

      },

      'CS1013':function(){

        var o = {
            'title':'You are not allowed to view this Policy',
            'desc':'LAMBDA - ListClientStatementNSREGIAS-Test',
            'text':'There are no client statements for this policy. If you need assistance, please contact us at 1-800-349-9267.',
        };

        processing('hide');
        syserror('show')(o.text);

    },
      'AC1001':function(){

          var o = {
              'title':'Not Authenticated',
              'desc':'LAMBDA - removeRep',
              'text':'Not Authenticated',
          };

          syserror('show')(o.text);

      },

      'HD1001':function(){

          var o = {
              'title':'Invalid request',
              'desc':'LAMBDA - GetMyAccounts-dev',
              'text':'Invalid request',
          };

          processing('hide');
          syserror('show')(o.text);

      },
      'HD1002':function(){

          var o = {
              'title':'Error reading registration table',
              'desc':'LAMBDA - SearchAgentBy*-dev',
              'text':'Error reading registration table',
          };

          processing('hide');
          syserror('show')(o.text);

      },
      'HD1003':function(){

          var o = {
              'title':'Error reading registration table',
              'desc':'LAMBDA - GetMyAccounts-dev',
              'text':'Error reading web profile table',
          };

          processing('hide');
          syserror('show')(o.text);

      },
      'HD1004':function(){

          var o = {
              'title':'Error reading cognito',
              'desc':'LAMBDA - GetMyAccounts-dev',
              'text':'Error reading cognito',
          };

          processing('hide');
          syserror('show')(o.text);

      },

      'HD1006':function(){

          var o = {
              'title':'Invalid Request',
              'desc':'LAMBDA - resendinvite',
              'text':'Invalid Request',
          };

          syserror('show')(o.text);

      },

      'HD1008':function(){

          var o = {
              'title':'No access',
              'desc':'LAMBDA - OSearchClientByEmail-dev',
              'text':'No access',
          };

          syserror('show')(o.text);

      },

      'HD1009':function(){

          var o = {
              'title':'Cannot Edit',
              'desc':'LAMBDA - OUpdateProfile',
              'text':'Please check your information. This account cannot be updated to this email address.',
          };

          syserror('show')(o.text);

      },

      'HD1010':function(){

          var o = {
              'title':'No Record Found',
              'desc':'LAMBDA - SearchAgentBy*-dev2',
              'text':'No Record Found',
          };

          processing('hide');
          syserror('show')(o.text);

      },
      'HD1011':function(){
          var o = {
              'title':'Invalid Request',
              'desc':'LAMBDA - OChangePassword-dev',
              'text':'Invalid Request',
          };

          processing('hide');
          syserror('show')(o.text);
      },
      'HD1012':function(){
          var o = {
              'title':'Error reading web profile',
              'desc':'LAMBDA - OSearchClientByEmail-dev',
              'text':'Error reading web profile',
          };

          processing('hide');
          syserror('show')(o.text);
      },

      'HD1014':function(){
          var o = {
              'title':'Error reading cognito',
              'desc':'LAMBDA - EnableDisableAccount-dev',
              'text':'Error reading cognito',
          };

          processing('hide');
          syserror('show')(o.text);
      },

      'HD1015':function(){
          var o = {
              'title':'Error disabling UserStatus',
              'desc':'LAMBDA - EnableDisableAccount-dev',
              'text':'Error in disabling the User Status',
          };

          processing('hide');
          syserror('show')(o.text);
      },

      'HD1016':function(){
          var o = {
              'title':'Error Removing User',
              'desc':'LAMBDA - EnableDisableAccount-dev',
              'text':'Error in Removing the User',
          };

          processing('hide');
          syserror('show')(o.text);
      },

      'HD1017':function(){
          var o = {
              'title':'Error enable user',
              'desc':'LAMBDA - EnableDisableAccount-dev',
              'text':'Error in enabling the user',
          };

          processing('hide');
          syserror('show')(o.text);
      },

      'HD1018':function(){
          var o = {
              'title':'User not found',
              'desc':'LAMBDA - EnableDisableAccount-dev',
              'text':'User not found',
          };

          processing('hide');
          syserror('show')(o.text);
      },

      'HD1020':function(){

          var o = {
              'title':'Web_Profile data issue',
              'desc':'LAMBDA - GetMyAccounts-dev',
              'text':'Web_Profile data issue',
          };

          processing('hide');
          syserror('show')(o.text);

      },
        'HD1021':function(){

            var o = {
                'title':'Too many records returned',
                'desc':'LAMBDA - OSearchAgentByName',
                'text':'There are too many records that match the criteria entered. Please refine your search.',
            };

            processing('hide');
            syserror('show')(o.text);

        },

      'PP1001':function(){

                var o = {
          'title':'Invalid request',
          'desc':'LAMBDA - GetProducerAppointments-dev',
          'text':'Invalid request',
        };

                  processing('hide');
          syserror('show')(o.text);
      },
      'PP1002':function(){

                var o = {
          'title':'Failed to query the table',
          'desc':'LAMBDA - GetProducerAppointments-dev',
          'text':'Failed to query the table',
        };

                  processing('hide');
          syserror('show')(o.text);
      },

      'DB1010':function(){

                var o = {
          'title':'ValidationException',
          'desc':'LAMBDA - ?',
          'text':'database error?',
        };

                  processing('hide');
          syserror('show')(o.text);
      },
      'DA1001':function(){

          var o = {
              'title':'Failed to assume data role',
              'desc':'LAMBDA - ClientSignUp-dev',
              'text':'Failed to assume data role.',
          };
          processing('hide');
          syserror('show')(o.text);

      },
      'DB1002':function(){

          var o = {
              'title':'Error update Database',
              'desc':'LAMBDA - DismissNotification-test',
              'text':'Error in updating Database.',
          };
          processing('hide');
          syserror('show')(o.text);

      },
      'DB1003':function(){

          var o = {
              'title':'Invalid request',
              'desc':'LAMBDA - DismissNotification-test',
              'text':'Invalid request.',
          };
          processing('hide');
          syserror('show')(o.text);

      },

      'NB1001':function(){

          var o = {
              'title':"Invalid request",
              'desc':'LAMBDA -SearchCasesPending-dev (SearchCases-test)',
              'text':"Invalid request (Authentication)",
          };

          processing('hide');
          syserror('show')(o.text);

      },
      'NB1002':function(){

          var o = {
              'title':"Error reading Database",
              'desc':'LAMBDA -SearchCasesPending-dev (SearchCases-test)',
              'text':"Error reading Database",
          };

          processing('hide');
          syserror('show')(o.text);

      },

      'NB1003':function(){

          var o = {
              'title':"Case/policy number not found",
              'desc':'LAMBDA -GetCaseDetail-test',
              'text':"Case/policy number not found",
          };

          processing('hide');
          syserror('show')(o.text);

      },

      'NB1004':function(){

          var o = {
              'title':"You do not have access to view this case/policy number",
              'desc':'LAMBDA -GetCaseDetail-test',
              'text':"You do not have access to view this case/policy number",
          };

          processing('hide');
          syserror('show')(o.text);

      },

      'NB1012':function(){

          var o = {
              'title':"Error accessing reports",
              'desc':'LAMBDA -SearchCasesPending-dev (SearchCases-test)',
              'text':"Error accessing reports",
          };

          processing('hide');
          syserror('show')(o.text);

      },

      'NB1015':function(){

          var o = {
              'title':'Error No Data for Metrics',
              'desc':'LAMBDA -GetAgentMetrics-dev',
              'text':'There is no pending new business data available for this user.<br>Inforce policy/contract data is available under Account Values in the Track menu.<br/>Note: Medicare Supplement Insurance data is not available on the Dashboard.  For case status, visit New Business Search in the Track menu.',
          };

          processing('hide');
          syserror('show')(o.text);

      },
      'NB1016':function(){

          var o = {
              'title':'No reports available for the user',
              'desc':'LAMBDA -GetAgentMetrics-dev',
              'text':'No reports are available for the user',
          };

          processing('hide');
          syserror('show')(o.text);

      },


      'CA1004':function(){

          var o = {
              'title':'Error reading cognito',
              'desc':'LAMBDA - RemoveCompanyAdmin-dev',
              'text':'Error reading cognito',
          };

          processing('hide');
          syserror('show')(o.text);

      },
      'CA1007':function(){

          var o = {
              'title':"Cannot Delete User",
              'desc':'LAMBDA -RemoveCompanyAdmin-dev',
              'text':"User should not be deleted from this page. User do not have CompanyAdmin group",
          };

          processing('hide');
          syserror('show')(o.text);

      },
      'AV0001': function(){
          var o = {
              'title':'Invalid Account Number',
              'desc':'',
              'text':'We are unable to process your request at this time. Please call a Variable Products Representative at (800) 541-0171.  As a convenience, please reference error: [0001]',
          };

          processing('hide')
          syserror('show')(o.text);
      },

      'AV0002': function(){
          var o = {
              'title':'Policy Surrendered',
              'desc':'',
              'text':'We are unable to process your request at this time due to the status of your policy. Please call a Variable Products Representative at (800) 541-0171.  As a convenience, please reference error: [0002] Surrendered',
          };

          processing('hide')
          syserror('show')(o.text);
      },

      'AV0003': function(){
          var o = {
              'title':'Policy restricted',
              'desc':'',
              'text':'We are unable to process your request at this time due to the status of your policy. Please call a Variable Products Representative at (800) 541-0171.  As a convenience, please reference error: [0003]',
          };

          processing('hide')
          syserror('show')(o.text);
      },

      'AV0004': function(){
          var o = {
              'title':'User Not Authorized',
              'desc':'',
              'text':'We are unable to process your request at this time.  You are not authorized to process transactions for this policy.  Please call a Variable Products Representative at (800) 541-0171.  As a convenience, please reference error: [0004]',
          };

          processing('hide')
          syserror('show')(o.text);
      },

      'AV0005': function(){
          var o = {
              'title':'Plan Not Supported',
              'desc':'',
              'text':'We are unable to process your request at this time.  The plan this policy does not allow this type of transaction.  Please call a Variable Products Representative at (800) 541-0171.  As a convenience, please reference error: [0005]',
          };

          processing('hide')
          syserror('show')(o.text);
      },

      'AV0009': function(){
          var o = {
              'title':'Please contact customer service at 800-541-0171 during the hours of 8:30 AM to 5:00 PM Monday through Friday.',
              'desc':'',
              'text':'Please contact customer service at 800-541-0171 during the hours of 8:30 AM to 5:00 PM Monday through Friday.',
          };

          processing('hide')
          syserror('show')(o.text);
      },
      'AV0010': function(){
          var o = {
              'title':'In Model TRX Not Allowed',
              'desc':'',
              'text':'We are unable to process your request at this time.  Changes to your current subaccount allocations will impact your elected Asset Allocation Program.  Please call a Variable Products Representative at (800) 541-0171.  As a convenience, please reference error: [0010]',
          };

          processing('hide')
          syserror('show')(o.text);
      },

      'AV0051': function(){
          var o = {
              'title':'VRU Fund Limit Error',
              'desc':'',
              'text':'VRU Fund Limit Error',
          };

          processing('hide')
          syserror('show')(o.text);
      },

      'AVHX00': function(){
          var o = {
              'title':'In Model TRX Not Allowed',
              'desc':'',
              'text':'We are unable to process your request at this time.  Changes to your current subaccount allocations will impact your elected Asset Allocation Program.  Please call a Variable Products Representative at (800) 541-0171.  As a convenience, please reference error: [XH00]',
          };

          processing('hide')
          syserror('show')(o.text);
      },

      'AVXJ30': function(){
          var o = {
              'title':'DISRPTD Policy OE Not Allowed',
              'desc':'',
              'text':'DISRPTD Policy OE Not Allowed',
          };

          processing('hide')
          syserror('show')(o.text);
      },




      'AV1001': function(){
          var o = {
              'title':"Invalid request",
              'desc':'LAMBDA -SearchAgentPolicies-dev',
              'text':"Invalid request",
          };

          processing('hide')
          syserror('show')(o.text);
      },
      'AV1002':function(){
         var o = {
              'title':"No records found",
              'desc':'LAMBDA -SearchAgentPolicies-dev',
              'text':"Policy not found.",
          };

          processing('hide')
          syserror('show')(o.text);
      },

      'AV1003':function(){
         var o = {
              'title':"Feature not available",
              'desc':'LAMBDA -SearchAgentPolicies-dev',
              'text':"This feature is not currently available for your policy/contract type.",
          };

          processing('hide')
          syserror('show')(o.text);
      },

      'AV1011':function(){
         var o = {
              'title':"You are not allowed to view this Policy",
              'desc':'LAMBDA -SearchAgentPolicies-dev',
              'text':"You do not have access to view this record. Please call 1-800-541-0171, for assistance.",
          };

          processing('hide')
          syserror('show')(o.text);
      },

      'AV1012':function(){
         var o = {
              'title':"You are not allowed to view this Policy",
              'desc':'LAMBDA -SearchAgentPolicies-dev',
              'text':"This producer or company is not authorized to view this policy/client information.",
          };

          processing('hide')
          syserror('show')(o.text);
      },

      'AV1013':function(){
          var o = {
              'title':"Too many records to view,Please enter additional search criteria",
              'desc':'LAMBDA -SearchAgentPolicies-dev',
              'text':"There are too many records that match the criteria entered.  Please refine your search.",
          };

          processing('hide')
          syserror('show')(o.text);
      },

      'AV1019':function(){
         var o = {
              'title':"Canceled Policy",
              'desc':'LAMBDA-getAccountValueLegacy',
              'text':"The status of this policy does not provide online account details.",
          };

          processing('hide')
          syserror('show')(o.text);
      },

      'AV1021':function(){
         var o = {
              'title':"Failed to assume data role",
              'desc':'LAMBDA -SearchAgentPolicies-dev',
              'text':"Failed to assume data role",
          };

          processing('hide')
          syserror('show')(o.text);
      },

      'AV1015':function(){
         var o = {
              'title':'You are not allowed to view this Policy',
              'desc':'LAMBDA -SearchAgentPolicies',
              'text':'Not all policy information is available at this time. Please contact your administrator at 1-800-349-9267.',
          };

          processing('hide')
          syserror('show')(o.text);
      },

    'AD1031':function(){
         var o = {
              'title':'AWS Email attachment error',
              'desc':'LAMBDA - ProcessAgentUpload',
              'text':'Error sending upload to process',
          };

          processing('hide')
          syserror('show')(o.text);
      },
      'AD10031':function(){
        var o = {
             'title':'The Policy dose not exist',
             'desc':'The Policy dose not exist',
             'text':'The Policy dose not exist',
         };

         processing('hide')
         syserror('show')(o.text);
     },

  };

