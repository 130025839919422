var validations = {

    'validEmail':{
        'desc':'',
        'pattern':/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'test': function(val){

            return this.pattern.test(val);
        },
        'true':'',
        'false':'Please enter a valid format.',
    },

    'atLeast2Letters':{
        'desc':'',
        'pattern':/^[a-zA-Z\s\-'.]{2,}$/,
        'test': function(val){

            return this.pattern.test(val);
        },
        'true':'',
        'false':'Please enter at least two letters.',

    },

    'companyCodeValidator':{
        'desc':'',
        'pattern':/^[a-zA-Z\s]{2,}$/,
        'test': function(val){

            return this.pattern.test(val);
        },
        'true':'',
        'false':'Please enter at least two letters.',

    },

    'noMoreThan50':{
        'desc':'',
        'pattern':/^[a-zA-Z.,\-'&]{1,50}$/,
        'test': function(val){

            return this.pattern.test(val);
        },
        'true':'',
        'false':'Please enter at least two letters.',

    },

    'noNumbers':{
        'desc':'',
        'pattern':/^\d+$/,
        'test': function(val){
            return !this.pattern.test(val);
        },
        'true':'',
        'false':'Please do not use numbers.',
    },

    'noLetters':{
        'desc':'',
        'pattern':/^[a-zA-Z.,\-'&]+$/,
        'test': function(val){
            return !this.pattern.test(val);
        },
        'true':'',
        'false':'Only use Numbers.',
    },

    'notAllowedOne':{
        'desc':'',
        'pattern':/[!@#$%^&*()_+=\[\]{};:"\\|<>,\/?]+/, 
        'test': function(val){
            return !this.pattern.test(val);
        },
        'true':'Please enter a valid format',
        'false':'special characters not allowed',
    },

    'atLeastEightCharacters':{
        'desc':'',
        'pattern':/^[a-zA-Z0-9\$*^.\[\]{}()?\-"!@#$%&\/\\,<>':;|_~`]{8,}$/,
        'test': function(val){

            return this.pattern.test(val);
        },
        'true':'',
        'false':'Please enter a valid format',
    },

    'policyNumberValidator':{
        'desc':'',
        'pattern':/^[a-zA-Z0-9\$*^.\[\]{}()?\-"!@#$%&\/\\,<>':;|_~`]{8,10}$/,
        'test': function(val){

            return this.pattern.test(val);
        },
        'true':'',
        'false':'Policy Number must be at least 8 digits',
    },

    'oneSpecialCharacter':{
        'desc':'',
        'pattern':/[\$*^.\[\]{}()?\-"!@#$%&\/\\,<>':;|_~`]+/, 
        'test': function(val){
            return this.pattern.test(val);
        },
        'true':'',
        'false':'Please enter a valid format',
    },

    'oneNumber':{
        'desc':'',
        'pattern':/[0-9]+/,
        'test': function(val){
            return this.pattern.test(val);
        },
        'true':'',
        'false':'Please enter a valid format',
    },

    'validProducerCode':{
        'desc':'',
        'pattern':/^[0-9]{6}$/,
        'test': function(val){
            return this.pattern.test(val);
        },
        'true':'',
        'false':'Producer Code must be 6 digits',
    },

    'validRoutingNumber':{
        'desc':'',
        'pattern':/^[0-9]{9}$/,
        'test': function(val){
            return this.pattern.test(val);
        },
        'true':'',
        'false':'Routing Number must be 9 digits',
    }, 

    'validAccountNumber':{
        'desc':'',
        'pattern':/^[0-9]{2,15}$/,
        'test': function(val){
            return this.pattern.test(val);
        },
        'true':'',
        'false':'Account Number cannot be more than 15 digits.',
    }, 

        'oneLowerCase':{
        'desc':'',
        'pattern':/[a-z]+/,
        'test': function(val){
            return this.pattern.test(val);
        },
        'true':'',
        'false':'Please enter a valid format',
    },

    'oneUpperCase':{
        'desc':'',
        'pattern':/[A-Z]+/,
        'test': function(val){
            return this.pattern.test(val);
        },
        'true':'',
        'false':'Please enter a valid format',
    },

    'npn':{
        'desc':'',
        'pattern':/^\d{1,10}$/,
        'test': function(val){
            return this.pattern.test(val);
        },
        'true':'',
        'false':'NPN cannot be more than 10 digits',
    },

    'validSSN':{
        'desc':'',
        'pattern':/^(\d{3})-?\s*(\d{2})-?\s*(\d{4})$/,
        'test': function(val){

            return this.pattern.test(val);

        },
        'true':'',
        'false':'Please enter a valid SSN. SSN/TIN must be 9 digits.',
    },

    'validPhone':{

        'desc':'',
        'pattern':/^\(?[0-9]{3}\)?-?\s*[0-9]{3}-?\s*[0-9]{4}$/,
        'test':function(val){

            return this.pattern.test(val);

        },
        'true':'',
        'false':'Please enter a valid Phone Number. '
    },

    'validFax':{

        'desc':'',
        'pattern':/^\(?[0-9]{3}\)?-?\s*[0-9]{3}-?\s*[0-9]{4}$/,
        'test':function(val){

            return this.pattern.test(val);

        },
        'true':'',
        'false':'Please enter a valid fax number. '
    },

    'required':{
        'desc':'',
        'test': function(val){

            if(val !== undefined && val !== 'undefined' && val !== '' && val !== null){

                return true;
            } else {

                return false;
            }
        },
        'true':'',
        'false':'It looks like you have left some fields blank. Please complete the required fields.',
        'confirmationCode':'Please enter the code that was sent to you.'
    },

    'requiredLastname':{
        'desc':'',
        'test': function(val){

            if(val !== undefined && val !== 'undefined' && val !== '' && val !== null){

                return true;
            } else {

                return false;
            }
        },
        'true':'',
        'false':'You must enter a last name'
    },

    'requiredCode':{
        'desc':'',
        'test': function(val){

            if(val !== undefined && val !== 'undefined' && val !== '' && val !== null){

                return true;
            } else {

                return false;
            }
        },
        'true':'',
        'false':' Please enter the code that was sent to you.'
    },

    'validZip':{

        'desc':'',
        'pattern':/(^\d{5}$)|(^\d{5}-\d{4}$)/,
        'test':function(val){

            return this.pattern.test(val);

        },
        'true':'',
        'false':'Please enter a valid Zip Code . '
    },
};


