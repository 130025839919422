
let vtemplates = {

  columnwrap: 

  	`<div class="row justify-content-center">
  		<div v-bind:class="'col-xs-12 col-sm-10 col-md-'+size">

  			<slot></slot>

  		</div>
  	</div>`,

  bannerwrap: 

  	`<div class="row">
  	  <header class="blog-header small-screen-center text-light">
        <div class="container">
          <div class="row">
            <div class="col-md-8 col-sm-12">
                <h1 class="text-left element-top-30 element-bottom-30 text-light big">{{title}}</h1>
            </div>

      	    <div class="col-md-4 col-sm-12">
          	    <ol class="breadcrumb breadcrumb-blog element-top-30">
          	      <li>
          	        <a href="../index.html" style="text-decoration: underline">Home</a>
                  </li>
                  <li v-if="title == 'RESPOND TO NEW BUSINESS'" class="active">RESPONSE FORM</li>
          	      <li v-else class="active">{{title}}</li>
          	    </ol>
            </div>
          </div>
        </div>

  	  </header>
  	</div>`,

  mainbutton: 

  	`<div class="row element-top-10 element-bottom-40">

        <div class="col-md-12 text-center">
          <!-- Button references method on parent Vue instance -->
          <div
            class="btn btn-success btn-lg  text-normal btn-icon-right  element-left-0 element-right-0 element-top-0 element-bottom-0"
            v-on:click="click(comp)"
            style="background-color: #ff7932 !important;">{{title}}<i class="fa fa-caret-right" data-animation="none"></i>
          </div>
        </div>

      </div>`,

  smallbutton: 

    `<div class="row element-top-10 element-bottom-10">

        <div class="col-md-12 text-center">
          <!-- Button references method on parent Vue instance -->
          <div :id="id?id:''"
            class="btn btn-success btn-sm text-normal btn-icon-right element-left-0 element-right-0 element-top-0 element-bottom-0"
            v-on:click="click(comp)"
            v-bind:style="'background-color: '+color+' !important;'">{{title}}<i class="fa fa-caret-right" data-animation="none"></i>
          </div>
        </div>

      </div>`,

  pagesubheader: 

  	`<div class="row">

        <div v-bind:class="'col-md-12 col-xs-12 text-'+align">

          <h2>{{title}}</h2>

          <div v-bind:class="'divider-border divider-border-'+align+' element-top-10 element-bottom-10'" style="height:3px;">
            <div class="divider-border-inner" v-bind:style="'width:'+linewidth"></div>
          </div>

          <p v-if="subtitle" v-bind:class="'lead text-'+align">{{subtitle}}</p>

          <br>
          <slot></slot>
          <br v-if="this.$slots.default">
          <br v-if="this.$slots.default">

        </div>

    	</div>`,


  pageback: 

  	    `<div class="row element-top-50">

        <div class="col-md-12">
          <div style="width:50%;height:25px;display:block; cursor: pointer" v-on:click="click()">
            <span class="fa fa-reply"></span>&nbsp;{{title}}
          </div>
        </div>

    </div>`,

  generalheader:

      `<div class="row">

          <div v-bind:class="leftsize ? 'col-md-'+leftsize+' element-bottom-10' :'col-xs-9 col-sm-10 col-md-10 element-bottom-10'">
             <slot name="left">

              </slot>
          </div>


          <div v-bind:class="rightsize ? 'col-md-'+rightsize+' element-bottom-10' :'col-xs-3 col-sm-2 col-md-2 element-bottom-10'">
            <slot name="right">

            </slot>

            <div v-if="menu" class="dropdown">
                
                <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown">{{headerdata.button[1]}}
                  <span class="caret"></span>
                </button>

                <ul class="dropdown-menu" v-bind:style="headerdata.buttonDropdownStyle">
                  <li v-for="(item, index) in menu" v-bind:key="index" v-on:click="$root[headerdata.buttonClick](index, $event)">
                  <a href="javascript:;" style="text-decoration:none">{{item.name}}</a></li>
                </ul>
              
            </div>

          </div>

      </div>`,


  forminput: 
  `<div class="row justify-content-center element-top-10 element-bottom-20">

    <div class="col-md-12 col-xs-12" style="margin-top:5px">

       <div class="row form-group form-icon-group" v-for="(input,index) in obj.inputs" v-bind:key="index">

            <template v-if="R.is(Array,input)">

              <div class="col-md-12 col-xs-12" style="margin-top:5px">
                <label class="NSRE-form" v-bind:for="Object.keys(obj.data)[index]" v-html="input[0]"></label>
              </div>

              <div class="col-md-12 col-xs-12" style="margin-top:5px">
                <div class="col-md-6 col-xs-12" v-for="(item, i) in R.drop(1, input)" v-bind:key="i">
                  <div style="margin-top:2px;float:left">{{item}}</div>&nbsp;
                  <input v-bind:value="item" v-bind:name="Object.keys(obj.data)[index]" v-bind:id="Object.keys(obj.data)[index] +'-'+ item" v-bind:type="obj.types[index]" autocomplete="off" v-model="obj.data[Object.keys(obj.data)[index]]"/>
                </div>
              </div>

            </template>

            <template v-else>

              <div class="col-md-12 col-xs-12" style="margin-top:5px">

                <label class="NSRE-form" v-bind:for="Object.keys(obj.data)[index]" v-html="input"></label>
                <br>
                <input style="width:100%;" required maxlength="50" v-bind:name="Object.keys(obj.data)[index]" v-bind:id="Object.keys(obj.data)[index]" v-bind:type="obj.types[index]" autocomplete="off" v-model="obj.data[Object.keys(obj.data)[index]]"/>

              </div>

            </template>

        </div>

      </div>
    </div>`,

  forminput2: 
  `<div class="row justify-content-center element-top-10 element-bottom-20">

        <div class="col-md-12 col-xs-12" style="margin-top:5px">

           <div class="row form-group form-icon-group" v-for="(input,index) in obj.inputs" v-bind:key="index">

                
              <template v-if="R.is(Array,input)">

                <div>
                    <div class="col-md-6 col-xs-12" style="margin-top:5px">
                        <label class="NSRE-form" v-bind:for="Object.keys(obj.data)[index]" v-html="input[0]"></label>
                    </div>
                    
                    <div class="col-md-6 col-xs-12" style="margin-top:5px">
                      <div class="col-md-6 col-xs-12" v-for="(item, i) in R.drop(1, input)" v-bind:key="i">
                          <input v-bind:value="item" v-bind:name="Object.keys(obj.data)[index]" v-bind:id="Object.keys(obj.data)[index] +'-'+ item" v-bind:type="obj.types[index]" autocomplete="off" v-model="obj.data[Object.keys(obj.data)[index]]"/>
                          &nbsp;<div style="margin-top:2px;display:inline-block;font-size:12px">{{item}}</div> 
                      </div>
                    </div>
                </div>
                
              </template>


              <template v-else-if="input[0] == '<'">

                  <div>
                    <div class="col-md-12 col-xs-12" style="margin-top:5px">
                          
                        <input style="float:left; margin-top:7px;margin-right:8px;" value="yes" v-bind:name="Object.keys(obj.data)[index]" v-bind:id="Object.keys(obj.data)[index]" v-bind:type="obj.types[index]" autocomplete="off" v-model="obj.data[Object.keys(obj.data)[index]]"/> &nbsp;
                        
                        <label style="float:left" class="NSRE-form" v-bind:for="Object.keys(obj.data)[index]" v-html="input"></label>
                        
                    </div>

                     <div v-if="obj.notes[index]" class="col-md-12 col-xs-12" style="margin-top:5px;background-color:lightyellow" v-html="obj.notes[index]">
                      
                     </div>
                  </div>

              </template>

              <template v-else>

                  <div>
                    <div class="col-md-6 col-xs-12" style="margin-top:5px">

                      <label class="NSRE-form" v-bind:for="Object.keys(obj.data)[index]" v-html=input>
                      
                      </label>
                  
                    </div>
                    
                    <div class="col-md-6 col-xs-12" style="margin-top:5px">

                      <input style="width:100%;" v-bind:name="Object.keys(obj.data)[index]" v-bind:id="Object.keys(obj.data)[index]" v-bind:type="obj.types[index]" autocomplete="off" v-model="obj.data[Object.keys(obj.data)[index]]"/>

                    </div>
                    
                     <div v-if="obj.notes[index]" class="col-md-12 col-xs-12" style="margin-top:5px;background-color:lightyellow" v-html="obj.notes[index]">
                      
                     </div>
                  </div>

              </template>

            </div>

        </div>
      </div>`,



  fieldItem3: 

           `<div class="col-md-12" style="background-color: #ffffff;">
        <dl class="dl-horizontal">
          <dt><slot name="title"></slot></dt>
          <dd><slot name="value"></slot></dd>
        </dl>
      </div>`,


    inputitem: 

      `<div class="col-md-12" style="background-color: #ffffff;padding-top:10px;padding-bottom:10px">
        
        <div class="col-md-4 col-xs-12" style="margin-top:10px">

          <slot name="label"></slot>
          
        </div>
        
        <div class="col-md-8 col-xs-12" style="margin-top:10px">

          <slot name="input">

          </slot>

        </div>

      </div>`,


   databoxes: 

      `<div class="row">
        <div v-for="box in boxes" v-bind:class="'col-md-'+box.size">

            <template v-for="item in box.items">

                <slot v-bind:item="item" v-bind:data="box.data" v-bind:size="box.columnSizes">


                </slot>

            </template>

        </div>
      </div>`,

    boxtable:

      `<div class="col-md-12">

        <table width="100%" border="0" class="element-bottom-10">
          <tbody>

          <tr>
            <td colspan="2" v-html="table.tableTitle ? table.tableTitle : ''"></td>
          </tr>

          <tr>
            <td v-for="(header,index) in table.tableHeaders" v-bind:key="index" v-bind:style="table.columnSizes[index] ? 'height:20px;border-bottom:2px solid #6891c0;width:'+table.columnSizes[index] : 'height:20px;border-bottom:2px solid #6891c0;width:auto'"  v-html="'<strong>'+header+'</strong>'""></td>
          </tr>

          <template v-if="JSON.stringify(data) !== '[]'">

              <template v-for="(item, index) in data">

                  <tr>
                      <td v-for="attr in table.rowData[0]">
                        <span v-html="item[attr]"></span>
                      </td>
                  </tr>

                  <tr v-if="table.rowData[1]">
                    <td v-for="attr in table.rowData[1]" v-bind:colspan="table.rowData[0].length">
                      <span v-html="item[attr]"></span>
                    </td>
                  </tr>

              </template>

          </template>

          <template v-else>
                  
              <tr>
                  <td>
                    <span>No Results</span>
                  </td>
              </tr>

          </template>

        </tbody>
      </table>

      </div>`,

    genboxes: 

      `<div class="row">
         <div v-for="box in boxes" v-bind:class="'col-md-'+box.size+' nsreBOX'">
            
            <slot v-bind:box="box">


            </slot>
        </div>
      </div>`,

   displayboxes: 

      `<div class="row">

         <div v-for="box in boxes" v-bind:class="'col-sm-12 col-xs-12 col-md-'+box.size" style="margin-bottom:5px">

            <template v-for="item in box.items">

                <field-item v-if="(item.em == false || item.hasOwnProperty('em') == false) && (item.editable == true || item.hasOwnProperty('editable') == false)">

                  <strong slot="title" v-html="item.label"></strong>

                  <span slot="value" style="word-break:break-word;" v-html="data[item.value]"></span>

                </field-item>


                <input-item v-if="item.em == true && item.editable == true">

                    <label slot="label" class="NSRE-form" v-bind:for="item.value">{{item.label}}<span style="color: #e10000; font-weight: 500;"></span>
                    </label>

                    <input slot="input" style="width:100%;" v-bind:name="item.value" v-bind:id="item.value" type="" autocomplete="off" v-model="data[item.value]"/>

                </input-item>


              
            </template>

        </div>
      </div>`,


  generalpanel:

            `<div class="panel panel-info" style="background-color:rgba(0,0,0,0);margin-bottom:10px">

          <div class="panel-heading" v-if="panel.panelTitle">
            <h3 class="panel-title" v-html="panel.panelTitle"></h3>
          </div>


          <div class="panel-body">
            <slot>

            </slot>
          </div>

        </div>`,

  tabpane:

    `<div>
        <ul class="nav nav-tabs" id="" role="tablist">

          <li v-for="(tab, index) in tabs" v-bind:class="index == 0 ? 'nav-item active' : 'nav-item'">
            <a class="nav-link"
              v-bind:id="'t-'+Vue.options.filters.spaceToDash(tab.title)"
              data-toggle="tab"
              v-bind:href="'#tab-'+Vue.options.filters.spaceToDash(tab.title)" role="tab"
              v-bind:aria-selected="index == 0 ? 'true' : ''"
              v-html="tab.title"></a>
          </li>

        </ul>

        <div class="tab-content" id="">

          <slot v-bind:tabs="tabs">


          </slot>

        </div>
      </div>`,

  searchbox:

       `<div class="col-md-12 element-top-30">

        <input class="psearch" v-bind:placeholder="ptext" v-model="imodel" style="width:70%">

          <div href="#" class="btn btn-link btn-sm text-normal element-left-0 element-right-0 element-top-0 element-bottom-0" v-on:click="click">Search
          </div>

      </div>`,

  listwrap:

      `<div id="recent-posts-7" class="sidebar-widget  widget_recent_entries">
          <div id="sidebar-summary">
            <ul>

              <template v-for="(item,index) in data">

                  <slot v-bind:item="item" v-bind:comp="comp" v-bind:key="index">

                  </slot>

              </template>


              <li v-if="button" class="clearfix" style="padding-left:0;display:none">

                <a style="background: #009db8 !important;" class="btn btn-success btn-sm  text-normal btn-icon-right  element-left-0 element-right-0 element-top-0 element-bottom-0" v-on:click="button">More &gt;</a>

              </li>
            </ul>

          </div>
        </div>`,

  floatlist:
  `<div class="row">

      <div class="col-md-12">

        <template v-for="producer in data">

          <strong>{{producer[list.header]}} - {{producer.producerCode}}</strong>

          <ul style="margin:0px;padding:0px;margin-left:10px;margin-bottom:10px">

            <template v-for="(p, index) in producer[list.data]">

              <li style="display:block; float:left;width:240px;padding:5px;border:1px solid #eeeeee;">
              {{p.productName}}
              </li>

            </template>

             <br style="clear:both">

          </ul>

         

        </template>

      </div>

    </div>`,


  displaypanel:

       `<div class="panel panel-info" style="background-color:rgba(0,0,0,0);margin-bottom:10px">

          <div class="panel-heading" v-if="panel.panelTitle">
            <h3 class="panel-title">{{panel.panelTitle}}</h3>
          </div>

          <div v-if="panel.gheader" class="row" style="margin-left:0px;margin-right:0px;margin-top:10px">
            <div class="col-md-12">
              <general-header v-bind:menu="$root.makeMenu(panel.gheader.button)" v-bind:headerdata="panel.gheader">
                    <div v-if="panel.gheader.left" slot="left" v-html="panel.gheader.left"></div>
                    <div v-if="panel.gheader.right" slot="right" v-html="panel.gheader.right"></div>
              </general-header>
            </div>
          </div>

         <div class="panel-body" v-bind:style="(type == 'sub') ? 'padding-top:0px' : ''">



            <template v-if="panel.table">
              
              <template v-if="Array.isArray(panel.table)">

                  <!-- TODO: <array-table v-bind:panel="panel.table"> </array-table> -->

                  <template v-for="tableSingle in panel.table">
                    <!-- small hack for showUpline table -->
                    <display-table v-if="panel.data == 'showDetails'" v-bind:table="tableSingle" v-bind:data="data[panel.data][tableSingle.key]"></display-table>
                    <display-table v-else v-bind:table="tableSingle" v-bind:data="data[panel.data]"></display-table>
                  </template>

              </template>

              <template v-else>
                  <display-table v-bind:table="panel.table" v-bind:data="data[panel.data]"></display-table>
              </template>

            </template>


            <template v-else-if="panel.boxes">

             <template v-if="Array.isArray(data[panel.data]) && JSON.stringify(data[panel.data]) !== '[]'">

                  <template v-for="(attr, index) in data[panel.data]">

                      <general-header v-if="panel.subheader">
    
                          <div slot="left" v-html="panel.subheader[0]+'<span style=color:steelblue;font-weight:bold>'+attr[panel.subheader[1]]+'</span>'">

                          </div>

                          <div slot="right">

                            <template v-if="panel.subheader[2]">
                                <input v-if="panel.subheader[2]=='selectCheck'" type="checkbox" v-bind:name="panel.data" v-bind:value="index" v-model="panel.selected">
                                <div style="position:relative;margin-left:-100px"><input v-if="panel.subheader[2]=='selectRadio'" type="radio" v-bind:name="panel.data" v-bind:value="index" v-model="panel.selected" v-on:change="$root.selectRadio(panel.data)">&nbsp;&nbsp;<span v-if="panel.subheader[3]" style="font-size:10px">{{panel.subheader[3]}}</span></div>
                            </template>

                          </div>
                       </general-header>


                      <display-boxes v-if="panel.boxes" v-bind:boxes="panel.boxes" v-bind:data="attr"></display-boxes>


                      <div v-if="panel.sub" class="row sub">

                         <div class="col-md-12">

                            <template v-for="sub in panel.sub">

                                <display-panel v-bind:panel="sub" v-bind:data="attr" type="sub"></display-panel>

                            </template>
                          
                         </div>

                      </div>

                  </template>

              </template>

             <template v-else-if="typeof data[panel.data] == 'object' && JSON.stringify(data[panel.data]) !== '[]'">

                  <display-boxes v-bind:boxes="panel.boxes" v-bind:data="data[panel.data]"></display-boxes>

              </template>

          </template><!-- if boxes end -->


          <template v-else-if="panel.floatlist">
              
              <display-floatlist v-bind:data="data[panel.data]" v-bind:list="panel.floatlist"></display-floatlist>

          </template>


          <template v-else>

            <div>No Records.</div>

          </template>


          </div>
        </div>`,


    displaytable:

      `<div class="col-md-12">

        <div class="row">

         <h3 v-if="table.tableTitle">{{table.tableTitle}}</h3>

          <table class="table table-hover" style="font-size:14px !important;">

            <thead>
              <tr>
                <th v-for="header in table.tableHeaders">{{header}}</th>
              </tr>
            </thead>

            <tbody>


              <template v-if="JSON.stringify(data) !== '[]'">

                <template v-for="(item, index) in data">

                  <tr v-if="click" v-on:click="click(index)" v-bind:style="table.styles ? table.styles[0] : ''">

                    <td v-for="attr in table.rowData[0]">

                      <span v-if="attr == 'firstName' || attr == 'lastName' || attr == 'fullName'">
                        <a href="#" v-html="item[attr]"></a>
                      </span>

                      <span v-else v-html="item[attr]"></span>

                    </td>
                  </tr>

                  <tr v-else v-bind:style="table.styles ? table.styles[0] : ''">

                      <td v-for="attr in table.rowData[0]" v-bind:style="table.styles ? table.styles[2] : ''">

                        <span v-if="attr == 'copy'">
                          <input v-bind:id="'stcopy-'+index" name="s_training_copy" v-bind:value="index" type="checkbox">
                        </span>

                        <span v-else v-html="item[attr]"></span>

                      </td>
              
                  </tr>

                  <tr v-if="table.rowData[1]" v-bind:style="table.styles ? table.styles[1] : ''">
                    <td v-for="attr in table.rowData[1]" v-bind:colspan="table.rowData[0].length" v-bind:style="table.styles ? table.styles[3] : ''">
                      <span v-html="item[attr]"></span>
                    </td>
                  </tr>

                </template>

              </template>

              <template v-else>
                  
                  <tr>
                      <td>
                        <!-- hack for validateUpline panel -->
                        <span v-if="$root.displayUplinepanel" style="color:#FF0000;font-weight:bold">No Results</span>
                        <span v-else >No Results</span>

                      </td>
                  </tr>

              </template>



            </tbody>
          </table>

        </div>

        <div class="row">

          <!-- for pagination row -->
          <slot>

          </slot>

        </div>
      </div>`,





  resulttable: 

    `<div class="col-md-12">
        <div class="row">

          <table class="table table-hover" style="font-size:14px !important;">

            <thead>
              <tr>
                <th v-for="header in table.tableHeaders">{{header}}</th>
              </tr>
            </thead>

            <tbody>

                <template v-for="(item,index) in data">

                  <tr v-if="item[table.data]" v-on:click="click(index)">

                    <td v-for="attr in table.rowData[0]">

                      <span v-if="attr == 'firstName' || attr == 'lastName' || attr == 'fullName'">
                        <a href="javascript:;" v-html="item[table.data][attr]"></a>
                      </span>

                      <span v-else v-html="item[table.data][attr]"></span>

                    </td>
                  </tr>

                  <tr v-else><td colspan="6" class="text-center">No results</td></tr>

                  <tr v-if="table.rowData[1]">
                    <td v-for="attr in table.rowData[1]" v-bind:colspan="table.rowData[0].length">
                      <span v-html="item[table.data][attr]"></span>
                    </td>
                  </tr>

                </template>

            </tbody>
          </table>

        </div>
      </div>`,



  searchmenu: 

   `<div class="row justify-content-center element-top-20">

       <div class="col-md-8 col-xs-12" style="margin-top:5px">
           <div class="row">
              <div class="col-md-5 col-xs-12" style="margin-top:5px">{{label ? label : 'Search by :'}}</div>

              <div class="col-md-7 col-xs-12">

               
                <div class="select-wrap">
                  <select name="searchType" id="searchType" v-on:change="$root.changedValue">
                    <option v-for="(option, index) in menu" v-key="index" v-bind:value="option.value" v-bind:selected="selected == option.value">{{option.name}}</option>
                  </select>
                </div>

              </div>
            </div>
        </div>

    </div>`,


  searchinput: 

   	`<div class="row justify-content-center element-top-10 element-bottom-20">

      <div class="col-md-8 col-xs-12" style="margin-top:5px">

         <div class="row" v-for="(input,index) in obj.inputs" v-bind:key="index">

              <div class="col-md-5 col-xs-12" style="margin-top:5px">

                <label class="NSRE-form" v-bind:for="Object.keys(obj.data)[index]">{{input}}
                  <span style="color: #e10000; font-weight: 500;"></span>
                </label>

              </div>

              <div class="col-md-7 col-xs-12 form-group form-icon-group">

                <input style="width:100%;" v-bind:id="Object.keys(obj.data)[index]" type="text" autocomplete="off" v-model="obj.data[Object.keys(obj.data)[index]]"/>
              
              </div>

          </div>
      </div>
    </div>`,


  resultpanel: 
  `<div class="panel panel-info element-bottom-20">

      <div class="panel-heading">
        <h3 class="panel-title">{{panel.panelTitle}}</h3>
      </div>

      <div class="panel-body">
        <div class="row">

          <div v-for="box in panel.boxes" v-bind:class="'element-bottom-10 col-md-'+box.size">
              <field-item v-for="item in box.items">
                <strong slot="title">{{item.label}}</strong>

                <span slot="value">{{agent[panel.data][item.value]}}</span>
              </field-item>
          </div>

        </div>
      </div>

  </div>`,



  resultpanelheader: 
    `<div class="row element-top-10">

      <div class="col-md-10">
         <h2>{{title}}</h2>
      </div>

      <div class="col-md-2">

        <div class="dropdown element-top-20">
            <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown">{{buttonname}}
              <span class="caret"></span>
            </button>

            <ul class="dropdown-menu">
              <li v-for="item in menu"><a href="#">{{item.name}}</a></li>
            </ul>
        </div>

      </div>

    </div>`,


    processing:
      `<section id="processing" style="display:none;">

          <div class="row element-top-10 element-bottom-20">

              <div class="col-md-12 text-center element-top-100 element-bottom-100">
                <i class="fa fa-refresh fa-spin fa-3x fa-fw"></i>
              </div>
          </div>

      </section>`,

    validation:
      `<section id="validation" class="validation_error text-center" style="display:none;">
        <p></p>
      </section>`,


    systemerror:
      `<section id="systemError" class="validation_error text-center serverError element-bottom-50 element-top-50" style="display:none;">
        <p></p>
      </section>`,



    notifylist: 
      `<li class="clearfix" style="padding-left:0;">

          <span style="font-size:small;" v-html="item.date"></span>

          <span style="font-size:small;float:right">
            <i class="fa fa-square-o"></i>
          </span>

          <br/>

          <span v-html="item.message"></span>

      </li>`,

    newslist:
      `<li class="clearfix" style="padding-left:0;">
        <a v-bind:href="item.link" v-html="item.title"></a>
        <small class="post-date" v-html="item.date"></small>
      </li>`,

    toolslist:
      `<li class="clearfix" style="padding-left:0;">
            <a href="javascript:void(0)" @click="newTab(item.link)" class="NSRE-text-link" v-html="item.title"></a>
      </li>`,

    actionCompleteT:
    `<div id="actionCompleted" class="text-center" style="display:none;">
      <p style="margin-top:50px;margin-bottom:50px;font-size:18px"></p>
    </div>`,

    confirmActionT:
    `<div id="confirm" class="row element-bottom-20" style="display:none;">
        <div class="col-md-4">
        </div>
        <div class="col-md-4" style="border : 1px solid #d2d2d3; padding:10px;">

          <div id="confirmAction" class="col-md-6"><p></p>
          </div>
          <div class="col-md-3">
            <button class="btn btn-primary btn-sm dropdown-toggle" type="button"
                    v-on:click="click('ok')" data-toggle="dropdown">OK
            </button>
          </div>
          <div class="col-md-3">
            <button class="btn btn-primary btn-sm dropdown-toggle" type="button"
                   @click="click('cancel')" data-toggle="dropdown">Cancel
            </button>
          </div>

        </div>
      </div>`,

      hview:
      `<div class="row">

      <div class="col-md-12" v-for="(hierarchyIndiv, index) in h">

          <div :class="hierarchyIndiv.class">
                      
            <div @click="$root.searchForUpline(hierarchyIndiv.producerCode)">
              <a style="color:#930707" href="javascript:;">{{hierarchyIndiv.fullName}}</a>
            </div>

            <div>
              <strong>Role</strong>: <span> {{hierarchyIndiv.roleType}}</span>
            </div>
            
            <div>
              <strong>Producer Code</strong>:
              <span> {{hierarchyIndiv.producerCode}}</span>
            </div>


            <div>
              <Strong>Status</Strong> :
              <span> {{hierarchyIndiv.agreementStatus}}</span>
            </div>
            
            <template v-if="hierarchyIndiv.roleType !== 'SILENT PARTNER'">
              <div>
                <Strong>Primary Email</Strong> :
                <a class="smallEmail" v-bind:href="'mailto:'+hierarchyIndiv.primaryEmail"> {{hierarchyIndiv.primaryEmail}}</a>
              </div>
            </template>


          </div>

           <template v-if="index < h.length-1">
            <div class="text-center">
              <i class="fa fa-arrow-down "></i>
            </div>
          </template>

        </div>
      </div>`,


    hlist:
    `<div>
        <strong>Distribution Hierarchies</strong>
        <br>

        <div class="select-wrap">
          <select onchange="atom.changeHierarchies(this.value)">
            <option value=-1 selected>---</option>
            <option v-for="(hierarchy, index) in hs" v-bind:value="index">

                  <template v-if="hierarchy.hierarchy[0].fullName">
                    {{hierarchy.producerCode}} - {{hierarchy.hierarchy[0].fullName}}
                  </template>

                  <template v-else>
                    {{hierarchy.producerCode}} - {{hierarchy.fullName}}
                  </template>
                  
            </option>
          </select>
        </div>
      </div>`,


    we2_displaypanel:
    `<div class="panel panel-info" style="background-color:rgba(0,0,0,0);margin-bottom:10px">
  
            <div class="panel-heading" v-if="panel.panelTitle">
              <h3 class="panel-title">{{panel.panelTitle}}</h3>
            </div>
  
            <div v-if="panel.gheader" class="row" style="margin-left:0px;margin-right:0px;margin-top:10px">
              <div class="col-md-12">
                  <slot name="header" v-bind:panel="panel"></slot>
              </div>
            </div>
  
            <div class="panel-body" v-bind:style="(type == 'sub') ? 'padding-top:0px' : ''">
              <slot v-bind:panel="panel" v-bind:data="data"></slot>
            </div>
    </div>`,


    we2_generalheader:
      `<div class="row">
          <div v-bind:class="leftsize ? 'col-md-'+leftsize+' element-bottom-10' :'col-xs-8 col-sm-9 col-md-9 element-bottom-10'">
            <slot name="left">

            </slot>
          </div>

          <div v-bind:class="middlesize ? 'col-md-'+middlesize+' element-bottom-10' :'col-xs-1 col-sm-1 col-md-1 element-bottom-10'">
            <slot name="right">

            </slot>
          </div>

          <div v-bind:class="rightsize ? 'col-md-'+rightsize+' element-bottom-10' :'col-xs-3 col-sm-2 col-md-2 element-bottom-10'">
            <slot name="button">

            </slot>   
          </div>

      </div>`,

        we2_buttondropdown:
      `<div v-if="menu" class="dropdown">
      
        <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown">{{obj.button[1]}}
          <span class="caret"></span>
        </button>

        <ul class="dropdown-menu" v-bind:style="obj.buttonDropdownStyle">
          <li v-for="(item, index) in menu" v-bind:key="index" v-on:click="$root[obj.buttonClick](item, $event)">
          <a href="javascript:;" style="text-decoration:none">{{item.name}}</a></li>
        </ul>

      </div>`,

    we2_forminput:
      `<div class="row justify-content-center element-top-10 element-bottom-20">
        <div class="col-md-12 col-xs-12" style="margin-top:5px">
    
          <slot v-bind:obj="obj"></slot>
    
        </div>
      </div>`,

    we2_displayboxes:
      `<div class="row">
        <div v-for="box in boxes" v-bind:class="'col-md-'+box.size" style="margin-bottom:5px" v-bind:id="'b-'+box.items[0].value">
          <template v-for="item in box.items">
            
            <slot v-bind:item="item" v-bind:data="data">
                
            </slot>
      
          </template>
        </div>
      </div>`,

    we2_fielditem:
    `<div class="col-md-12" style="background-color: #ffffff;">
      <dl class="dl-horizontal">
        <div style="display:inline-block;float:left;width:40%;padding-right:50px"><slot name="title"></slot></div>
        <div style="display:inline-block;float:left;width:45%"><slot name="value"></slot></div>
        <div style="display:inline-block;float:left;width:15%"><slot></slot></div>
        <br style="clear:both" />
      </dl>
    </div>`,

  we2_columnwrap:
    `<div class="row justify-content-center" style="margin-right:0px;margin-left:0px">
      <div v-bind:class="'col-xs-12 col-sm-10 col-md-'+size">
        <slot></slot>
      </div>
    </div>`


}




