 function AuthToken(t){

    return (t) ? t : agentToken;
  }


  function ajaxPostAuth(url, data){
console.log('ajaxPostAuth ===> '+ url);
console.dir(data);
       return $.ajax({

          type: "POST",
          headers: {"Authorization": AuthToken(getPrincipal('pToken'))},
          url: url,
          contentType:"application/json",
          data: JSON.stringify(data),
          dataType: 'json',
          success:ajaxSuccess,
          error:ajaxError

        });
  }


  function ajaxGetAuth(url){

   return  $.ajax({

      type: "GET",
      headers: {"Authorization": AuthToken(getPrincipal('pToken'))},
      url: url,
      contentType:"application/json",
      dataType: 'json',
      success:ajaxSuccess,
      error:ajaxError     

    });
  }




  function ajaxPost(url, data) {

    return $.ajax({

      type: "POST",
      dataType: 'json',
      crossDomain: true,
      contentType: 'application/json',
      url: url,
      data: JSON.stringify(data),
      success: ajaxSuccess,
      error: ajaxError
    });
  }



 function ajaxPutFile(url, file) {

    return $.ajax({

      type: "PUT",
      url: url,
      contentType: file.type,  
      crossDomain: true,
      processData:false,
      data: file,
      success: ajaxSuccess,
      error: ajaxError
    });
  }



function ajaxSuccess(d){

    console.log(d);

    if(d == null){

      processing('hide');
      syserror('show')('Lambda returned null data');

    } else if (d.status === 'Failed'){

      if (d.errorCode !== undefined){

              if(errorCodes[d.errorCode]){
          errorCodes[d.errorCode]();
        }
      }

    } else if (d.errorMessage !== undefined){

      processing('hide');
      syserror('show')(d.errorMessage);

    }

}


function ajaxError(XMLHttpRequest, textStatus, errorThrown){


    console.log(`request: ${JSON.stringify(XMLHttpRequest)}`);
    console.log(`status: ${textStatus}`);
    console.log(`error: ${errorThrown}`);

    processing('hide');
    syserror('show')('We have encountered a problem processing this request. Please try again.');
}



function checkreCaptcha(){

    if (document.MMRecaptchaOk !== true){

      return false;

    } else {

      return true;

    }
}


function recaptchaCallback(){

    document.MMRecaptchaOk = true;

}



function validate(obj,d,i,j){

  var result = validations[j].test(d);

  console.log(`${Object.keys(obj.data)[i]} ${j} => ${d} ${validations[j].test(d)}`);

  if(!result){

          obj.messages.push(validations[j][result]);
  }

    return {[Object.keys(obj.data)[i]]:result};

}




function checkConfirmations(obj){

    if(checkNull(obj.confirm)){

      obj.confirm = R.map(function(n){

        var result = (obj.data[n[0]] === obj.data[n[1]]) ? true : false;

        console.log(`confirm = ${obj.data[n[0]]} :: ${obj.data[n[1]]} => ${result}`);


        (result) ? obj.messages.push('') : obj.messages.push(n[2]);
        return {[n[0]]:result};

      }, obj.confirm)

        return obj;

    } else {

        return obj;

    }

}




function checkValidations(obj){

     if(checkNull(obj.validation)){

        var mapIndexed = R.addIndex(R.map);

        obj.validation = mapIndexed(function(n,i){

            var d = obj.data[Object.keys(obj.data)[i]]

            d = (checkNull(d)) ? d : '';

            var validateMap = R.curry(validate)(obj)(d)(i); 

            var req = /^required/g;

            if(req.test(n[0])){ 

                return R.map(validateMap, n);

            } else { 

              if(d !== ''){ 

                return R.map(validateMap, n);

              } else { 

                return R.map(function(){

                  return {'notRequired': true};

                }, n);
              }

            }

        }, obj.validation);

        return obj;

      } else {

        return obj;
      }

}



function checktheStuff(obj){

    console.log("checking form data:", obj);

    obj.messages = [];

    obj = checkValidations(obj);
    obj = checkConfirmations(obj);

    obj.allChecks = R.map(n => n[Object.keys(n)[0]], R.flatten(R.concat(obj.validation,obj.confirm)));

    return obj;
}




function failedChecks(obj){

    processing('hide');

    addRedBox(obj);
    var notEmpty = R.compose(R.not, R.isEmpty);
    obj.messages = R.filter(notEmpty, obj.messages);

    obj.messages = R.uniq(obj.messages); 

    validation('show')(obj.messages.join('<br>'));

    }

function redirectToContactServicePolicyHolder(){

    ajaxPostAuth(clientContextURL).done(function(data){

                   if(R.has('status',data)){

             if( data.status == 'OK'){    

                  var c = data.encoded;

                 if(c){

            newTab(nsrePublicURL+'contact-service-policyholder.html?c='+c);

                       } else {

            newTab(nsrePublicURL+'contact-service.html');

                      }
      }
    }

   });


  }



