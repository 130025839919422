function birthdayFormat(date){

  var d = date.split('-');
  return d[2]+'-'+d[0]+'-'+d[1]; 

}

function phoneFormat(value) {

		  	if (!value) return ''
	value = value.toString()
	var s2 = (""+value).replace(/\+1/gi, '');
  s2 = s2.split('.').join('');
	var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
	return (!m) ? null : "(" + m[1] + ") " + m[2] + "-" + m[3];
}

function phoneFormatMasked(value) {

        if (!value) return ''
  value = value.toString()
  var s2 = (""+value).replace(/\+1/gi, '');
  s2 = s2.split('.').join('');
  var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
  return (!m) ? null : "(XXX) XXX - " + m[3];
}


function zipFormat(value) {

	if (!value) return ''
	var num = (""+value).replace(/\D/g,"");

	if(num.length==9){

		num = num.substr(0,5)+"-"+num.substr(5);
	}

	return num;
}


function allCaps(value) {

  	if (!value) return ''
  	value = value.toString()
  	return value.toUpperCase();
}

function date1(value){

    if (!value) return ''

    var d = moment(value, "YYYYMMDD").format("MMMM DD, YYYY");

    return d;

}

function date2(value){

    if (!value) return ''

    var d = moment(value, "YYYYMMDD").format("MM/DD/YYYY");
    if(d == 'Invalid date'){
      return value;
    }else{
      return d;
    }

}

function date3(value){ 

    if (!value) return ''

    var d = moment(value, "YYYY-MM-DD").format("MM-DD-YYYY");

    return d;

}

function date4(value){ 

    if (!value) return ''

    var d = moment(value, "YYYYMMDD").format("MM-DD-YYYY");

    return d;

}

function typeNames(value){
  	if (!value) return ''

  	return statementTypeNames[value.toLowerCase()]; 

             }

function spaceToDash(value){

    if (!value) return ''

    return value.replace(/\s+/g,"-");

             }

function removeLeadingZeros(str){

  return str.replace(/^0+/, '');
}


var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});



