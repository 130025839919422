'use strict';
var expiring = 60;
var expiringId;
var extSessionLength =      1800000;
var emplSessionLength = 14400000;
var sessionLength = extSessionLength;

function checkCompanyRepFunctions(p) {
  if (p === undefined) {return;}

  if (p.gs === undefined ) {return;}

  var access = false;
  if (p.gs.indexOf('a10006') > -1 || p.gs.indexOf('a10016') > -1 || p.gs.indexOf('a10001') > -1)
    {access = true;}

  if (!access) {return; } 

  $('#getStatusMenuItem').show();

  for (var i=0; i<p.gs.length; i++) {
    var g = p.gs[i];
    $('.'+g).show();  
  }
}


function checkAgentFunctions(p) {
  if (p === undefined) {return;}

  if (p.gs === undefined ) {return;}

  if ( p.gs.indexOf('a10012') > -1)
  {
    $('.agentLink').hide();
    return;
  }

  $('.agentLink').hide();

  for (var i=0; i<p.gs.length; i++) {
    var g = p.gs[i];
    $('.'+g).show();  
  }
}



function checkFunctions(p) {
  if (p === undefined) {return;}

  if (p.gs === undefined ) {return;}

  for (var i=0; i<p.gs.length; i++) {
    var g = p.gs[i];
    $('.'+g).show();  
  }
}


function signout(logoutApi, logoutUrl) {

  if (localStorage.principal === undefined) {
    localStorage.clear();
    sessionStorage.clear();
    document.cookie = 'pSession=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    window.location.href = logoutUrl;
    return;
  }

  var p = JSON.parse(localStorage.principal);
  localStorage.clear();
  sessionStorage.clear();
  document.cookie = 'pSession=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

  if (p.userType === 'Employee') {
    logoutUrl = '/index.html';
  }

  $('#loggingOut').show();
  $.ajax({
    type: 'POST',
    dataType: 'json',

    contentType : 'application/json',
    url: logoutApi,
    headers : {Authorization : p.pToken},
    data : JSON.stringify({})

  })
    .done(function(ret, status, xhr) {
      console.log(ret);
      window.location.href = logoutUrl;

    })
    .fail(function(ret) {
      console.log('error: '+ret);
      window.location.href = logoutUrl; 
    });

}


function touchSession() {
  expiring = 60;
  if (expiringId !== undefined) {
    clearInterval(expiringId);
  }
  $('#sessionTimerBlock').hide();
  localStorage.sessionTime = new Date().getTime();
}

function sessionExpiring(logoutApi, logoutUrl) {
  expiringId = setInterval(function() {
    expiring = expiring - 1;
    if (expiring > 55)
    { $('#sessionTimerBlock').show(); }
    var d = new Date().getTime();
    var t = parseInt(localStorage.sessionTime) ;
    if ((d - t) < sessionLength) {  
      clearInterval(expiringId);
      $('#sessionTimerBlock').hide();
      expiring = 60;
      return;
    }


    if ((d-t) > (sessionLength + 120*1000)) {
      clearInterval(expiringId);
      expiring = 60;
      signout(logoutApi, logoutUrl);
      return;
    }

    $('#sessionTimer').text(expiring);
    if (expiring < 0) {
      clearInterval(expiringId);
      expiring = 60;
      signout(logoutApi, logoutUrl);
    }
  }, 1000);
}


function checkSession(logoutApi, logoutUrl) {
  if (expiring < 60) {return;}

  var d = new Date().getTime();
  if (localStorage.sessionTime) {
    var t = parseInt(localStorage.sessionTime) ;
    if ((d - t) > sessionLength) { 
      console.log('Signing out');
      sessionExpiring(logoutApi, logoutUrl);
      return false;
    }
  }
  else {
    localStorage.sessionTime = d;
  }
  return true;
}

function onLoggedon(logoutApi, logoutUrl) {
  if (localStorage.principal) {

    $('#classicLogin').remove();

    if (!checkSession()) {return; }

    touchSession();

    var p = JSON.parse(localStorage.principal);

    if (p.userType === 'Employee') {
      $('#adminMenuItem').show();
      $('.agentMenu').hide();
      $('#agentProfileMenu').hide();
      $('#companyAdminMenu').hide();
      sessionLength = emplSessionLength;
      checkFunctions(p);
    }
    else if(p.userType === 'Agent' ) {
      $('#getStatusMenuItem').show();
      $('#companyAdminMenu').hide();

      checkAgentFunctions(p);  
    }
    else if(p.userType === 'CompanyAdmin') {
      $('#getStatusMenuItem').show();
      $('#companyAdminMenu').show();
      $('.agentLink').hide();
      checkFunctions(p);
    }
    else if(p.userType === 'CompanyRep') {
      $('#getStatusMenuItem').hide();
      $('#companyAdminMenu').hide();
      $('.agentLink').hide();
      checkCompanyRepFunctions(p);
    }



    if (p.userType === 'Client') {
      return;
    }

    $('#principalHeader').text('Hi '+ p['given_name']);

    $('#loginMenu').hide();
    $('#logoutMenu').show();

    if(p.matchingStatus !== 'Deferred'){

      $('#agentLinkProfileMenu').hide();
    }

    setInterval( function() {checkSession(logoutApi, logoutUrl);}, 10000);
  }
  else {
    console.log('No principal');

    $('#adminMenuItem').hide();
    $('#logoutMenu').hide();
  }
}

