let expiring = 60;
let expiringId;
let extSessionLength = 1800000;
let emplSessionLength = 14400000;
let sessionLength = extSessionLength;

var mainHome = '';


function isCompanyAdmin(p){

    return p ? (p.gs ? R.contains('a10004', p.gs) : false ) : false; 
}



function getUserData(){

    var userData = {};

    var p = JSON.parse(localValue('principal'));

    userData.header = '../shared/vue-header.html';
    userData.footer = '../shared/footer.html';

    if(p){

      userData.p = p;

      if (p.userType === 'Client' || p.userType === 'EntityClient') {

                    userData.header = '../shared/header-client2.html';
          userData.footer = '../shared/footer-client.html';
      }

    } else {

      userData.p = {'userType':'Public'};

    }


        return userData;
}
function topNavInfo(){
    let e = getUserData();
    makeMastheadMenu();
    $("#principalHeader").text("Hi " + e.p.given_name)
}
function touchSession() {
    expiring = 60;
    if (expiringId !== undefined) {
        clearInterval(expiringId);
    }
    $('#sessionTimerBlock').hide();
    localStorage.sessionTime = new Date().getTime();
}

function sessionExpiring(logoutApi, logoutUrl) {
    expiringId = setInterval(function() {
        expiring = expiring - 1;
        if (expiring > 55)
        { $('#sessionTimerBlock').show(); }
        var d = new Date().getTime();
        var t = parseInt(localStorage.sessionTime) ;
        if ((d - t) < sessionLength) {  
            clearInterval(expiringId);
            $('#sessionTimerBlock').hide();
            expiring = 60;
            return;
        }


        if ((d-t) > (sessionLength + 120*1000)) {
            clearInterval(expiringId);
            expiring = 60;
            signout(logoutApi, logoutUrl);
            return;
        }

        $('#sessionTimer').text(expiring);
        if (expiring < 0) {
            clearInterval(expiringId);
            expiring = 60;
            signout(logoutApi, logoutUrl);
        }
    }, 1000);
}


function checkSession(logoutApi, logoutUrl) {
    if (expiring < 60) {return;}

    var d = new Date().getTime();
    if (localStorage.sessionTime) {
        var t = parseInt(localStorage.sessionTime) ;
        if ((d - t) > sessionLength) { 
            console.log('Signing out');
            sessionExpiring(logoutApi, logoutUrl);
            return false;
        }
    }
    else {
        localStorage.sessionTime = d;
    }
    return true;
}


function signinToPortal(signinUrl) {
  window.location.href = signinUrl;
}


function signoutFromPortal() {
    signout(logoutApi, logoutUrl);
}

function signout(logoutApi, logoutUrl) {

    if (localStorage.principal === undefined) {
        localStorage.clear();
        sessionStorage.clear();
        document.cookie = 'pSession=; expires=Thu, 01 Jan 1970 00:00:00 UTC;  path=/';
        window.location.href = logoutUrl;
        return;
    }

    var p = JSON.parse(localStorage.principal);

    localStorage.clear();
    sessionStorage.clear();
    document.cookie = 'pSession=; expires=Thu, 01 Jan 1970 00:00:00 UTC;  path=/';

    if (p.userType === 'Employee') {
        logoutUrl = '/index.html';

    } else if(p.userType === 'EntityClient' || p.userType === 'IndividualClient'){

        logoutUrl = 'https://nfg.com/index.html';
    }
    else if (p.userType === 'Client') {
        logoutUrl = 'https://nfg.com/index.html';
    }


       $('#loggingOut').show();
    $.ajax({
        type: 'POST',
        dataType: 'json',

        contentType : 'application/json',
        url: logoutApi,
        headers : {Authorization : p.pToken},
        data : JSON.stringify({})

    })
        .done(function(ret, status, xhr) {
            console.log(ret);
            window.location.href = logoutUrl;

        })
        .fail(function(ret) {
            console.log('error: '+ret);
            window.location.href = logoutUrl; 
        });


    }

function changePrincipal(data) {
    var date = new Date();
    date.setTime( date.getTime() + (3600000*10));
    document.cookie = 'pSession='+data.principal.pToken+';expires='+date.toGMTString()+'; path=/';
    localStorage.principal = JSON.stringify(data.principal);


}

function getUserRoles(userData,roles){

    var fRoles = R.filter((n) => (n.type == userData.p.userType) && (n.id) && (R.contains(n.name, userData.p.gs)),roles);

    return fRoles;
}

function filteredDataByRole(data,roles){

    if(data){


    if(roles.length > 0){

        var fdata = R.map(function(n){

             return R.filter((x) => ((x.roles.length > 0) && (R.contains(n.id, x.roles))) || (x.roles.length == 0), data);

           }, roles);


         } else {


           var fdata = R.filter((x) => (x.roles.length == 0), data);

           console.log('public links:', fdata);

        }


        fdata = R.uniq(R.flatten(fdata));

        return fdata;


    } else {


      return [];

    }


}


function logoLink(){

  var referPage = document.referrer;
  var defaultPage = "https://nfg.com";

  var h = getHostNameFromURL(referPage);

  console.log("referring hostname",h);


  if(referPage){

    switch(h){

    case "signin.nfg.com":
      window.location.assign("https://signin.nfg.com/index.html");

    break;

    case "signin-test.nsre.com":
       window.location.assign("https://signin-test.nsre.com/index.html");

    break;

    case "salesnet.nfg.com":
       window.location.assign("https://salesnet.nfg.com/index.html");

    break;

    case "salesnet-test.nsre.com":
       window.location.assign("https://salesnet-test.nsre.com/index.html");

    break;

    case "salesnet-testb.nsre.com":
       window.location.assign("https://salesnet-testb.nsre.com/index.html");

    break;

     case "account.nfg.com":
       window.location.assign("https://account.nfg.com/index.html");

    break;

     case "account-test.nsre.com":
       window.location.assign("https://account-test.nsre.com/index.html");

    break;


        default:

      window.location.assign(defaultPage);
    break;
  }


  }else{

    window.location.assign(defaultPage);
  }


  }



function getHostNameFromURL(url){

  var a=document.createElement('a');
  a.href=url;
  return a.hostname;
}


function makeMastheadMenu(menuData){

      if($('body:not([class*="oxy-agent"])').length) {
    $('.navbar .dropdown').hover(function() {
        $(this).find('.dropdown-menu').first().stop(true, true).delay(oxyThemeData.hoverMenu.hoverDelay).fadeIn(parseInt(oxyThemeData.hoverMenu.hoverFadeDelay));
    }, function() {
        $(this).find('.dropdown-menu').first().stop(true, true).delay(oxyThemeData.hoverMenu.hoverDelay).fadeOut(parseInt(oxyThemeData.hoverMenu.hoverFadeDelay));
    });

  }

  $('#masthead .nav li.dropdown a').on('click', function(){
      var $dropdown = $(this);
      if($dropdown.parent().hasClass('open') && ($dropdown.attr('data-link') !== undefined) ) {
          window.location = $dropdown.attr('data-link');
      }
  });
}



function injectLinkingGroup(userData){

    if(userData.p.userType == "Agent" && userData.p.masterProducerId){

      userData.p.gs.push("u1002"); 

    } 

    if(userData.p.userType == "Agent" && !userData.p.masterProducerId && userData.p.matchingStatus == "Deferred"){

      userData.p.gs.push("u1001"); 

    }

    localStorage.principal = JSON.stringify(userData.p);

    return userData;
}


function dynaMenu(){

    console.log('woring');
    var e = getUserData();
    $("#portalFooter").load(e.footer, function() {
        window.console.log("footer is loaded.")
    }),

    $('#portalHeader').load('../shared/header.html', function() {
        window.console.log( 'non-vue header is loaded.' );

        onLoggedon(logoutApi, logoutUrl);

        let userData = getUserData();
        if (userData.p.userType !== 'Public') {
            $('#menu-main').show();
            $('.login-menu').hide();
            $('.logout-menu').show();
        } else {
            $('#menu-main').hide();
            $('.login-menu').show();
            $('.logout-menu').hide();
        }

        $('#portalFooter').load(userData.footer, function() {
            window.console.log( 'footer is loaded.' );
        });

    });

}

function getProxyPrincipal(endpointUrl, userEmail, hostname) {
    if (localStorage.principal === undefined) {
        localStorage.clear();
        sessionStorage.clear();
        document.cookie = 'pSession=; expires=Thu, 01 Jan 1970 00:00:00 UTC;  path=/';
        return;
    }

    var p = JSON.parse(localStorage.principal);
    if (p.userType != 'Employee') {
        return;
    }

    $.ajax({
        type: 'POST',
        dataType: 'json',

        contentType : 'application/json',
        url: endpointUrl,
        headers : {Authorization : p.pToken},
        data : JSON.stringify({userName : userEmail})

    })
        .done(function(ret, status, xhr) {
            console.log(ret);
            if (ret.status == 'OK') {

                localStorage.clear();
                sessionStorage.clear();
                if (ret.principal.userType == 'Client') {  
                    window.location.href = 'https://'+hostname+'/start.html?proxyCode='+ret.principal.sessionId;
                    return;
                }

                document.cookie = 'pSession=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';  
                var date = new Date();
                date.setTime( date.getTime() + (3600000*10));
                document.cookie = 'pSession='+ret.principal.pToken+';expires='+date.toGMTString()+'; path=/';
                localStorage.principal = JSON.stringify(ret.principal);

                window.location.href = '../index.html';  

                return;
            }
            else {
            }
        })
        .fail(function(ret) {
            console.log('error: '+ret);
        });




}