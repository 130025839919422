
var vueComps = [

      {
        "name":'column-wrap',
        "props":['size'],
        "html":'columnwrap'
      },
      {
        "name":'banner-wrap',
        "props":['title'],
        "html":'bannerwrap'
      },
      {
        "name": 'page-subheader',
        "props":['title','subtitle','linewidth','align'],
        "html":'pagesubheader'
      },
      {
        "name": 'page-back',
        "props":['title','click'],
        "html":'pageback'
      },
      {
        "name": 'main-button',
        "props":['click','comp','title'],
        "html":'mainbutton'
      },
      {
        "name": 'small-button',
        "props":['click','comp','title','color','id'],
        "html":'smallbutton'
      },
      {
        "name": 'search-menu',
        "props":['menu', "label","selected"],
        "html":'searchmenu'
      },
      {
        "name": 'search-input',
        "props":['obj'],
        "html":'searchinput'
      },
      {
        "name": 'result-table',
        "props":['table','data','click'],
        "html":'resulttable'
      },
      {
        "name": 'result-pheader',
        "props":['title','menu','buttonname'],
        "html":'resultpanelheader'
      },
      {
        "name": 'result-panel',
        "props":['panel','agent'],
        "html":'resultpanel'
      },
      {
        "name": 'field-item',
        "props":['obj'],
        "html":'fieldItem3'
      },
      {
        "name":'input-item',
        "props":['obj'],
        "html":'inputitem'
      },
      {
        "name":'display-boxes',
        "props":['boxes','data'],
        "html":'displayboxes'
      },
      {
        "name":'display-panel',
        "props":['panel','data','type'],
        "html":'displaypanel'
      },
      {
        "name":'new-display-panel',
        "props":['panel','data','type'],
        "html":'newdisplaypanel'
      },
      {
        "name": 'display-floatlist',
        "props": ['list', 'data'],
        "html": 'floatlist'
      },
      {
        "name":'tab-pane',
        "props":['tabs'],
        "html":'tabpane'
      },
      {
        "name":'search-box',
        "props":['ptext','imodel','click'],
        "html":'searchbox'
      },
      {
        "name":'display-list',
        "props":['comp','data','button'],
        "html":'listwrap'
      },
      {
        "name":'general-panel',
        "props":['panel'],
        "html":'generalpanel'
      },
      {
        "name":'data-boxes',
        "props":['boxes'],
        "html":'databoxes'
      },
      {
        "name":'box-table',
        "props":['table', 'data'],
        "html":'boxtable'
      },
      {
        "name":'general-boxes',
        "props":['boxes'],
        "html":'genboxes'
      },
      {
        "name":'display-table',
        "props":['table','data','click'],
        "html":'displaytable'
      },
      {
        "name": 'general-header',
        "props":['leftsize','rightsize','menu','headerdata'],
        "html":'generalheader'
      },
      {
        "name": 'form-input',
        "props":['obj'],
        "html":'forminput'
      },
      {
        "name": 'form-input2',
        "props":['obj'],
        "html":'forminput2'
      },
      {
        "name": 'processing-ani',
        "props":[],
        "html":'processing'
      },
      {
        "name": 'validation-pane',
        "props":[],
        "html":'validation'
      },
      {
        "name": 'error-pane',
        "props":[],
        "html":'systemerror'
      },
      {
        "name":'notify-list',
        "props":['item'],
        "html":'notifylist'
      },
      {
        "name":'news-list',
        "props":['item'],
        "html":'newslist'
      },
      {
        "name":'tools-list',
        "props":['item'],
        "html":'toolslist'
      },
      {
         "name": 'confirm-action',
         "props": ['click'],
         "html": 'confirmActionT'
      },
      {
         "name": 'action-complete',
         "props": [],
         "html": 'actionCompleteT'
      },
      {
         "name": 'hi-view',
         "props": ['h'],
         "html": 'hview'
      },
      {
         "name": 'hi-list',
         "props": ['hs'],
         "html": 'hlist'
      },




      {
        "name":'we2-display-panel',
        "props":['panel','data','type'],
        "html":'we2_displaypanel'
      },
      {
        "name": 'we2-general-header',
        "props":['leftsize','middlesize','rightsize'],
        "html":'we2_generalheader'
      },
      {
        "name": 'we2-form-input',
        "props":['obj'],
        "html":'we2_forminput'
      },
      {
        "name":'we2-display-boxes',
        "props":['boxes','data'],
        "html":'we2_displayboxes'
      },
      {
        "name": 'we2-field-item',
        "props":['obj'],
        "html":'we2_fielditem'
      },

      {
        "name": 'we2-button-dropdown',
        "props":['menu','obj'],
        "html":'we2_buttondropdown'
      },
      {
        "name":'we2-column-wrap',
        "props":['size'],
        "html":'we2_columnwrap'
      },

    ];



function vComp(arr){

    R.forEach(function(n){

            var y = R.filter(function(x){ return x.name == n}, vueComps)[0];

      Vue.component(y.name, {
        props:y.props,
        template:vtemplates[y.html],
      });

    }, arr);
};



   var odometerHTML = `

    <div v-bind:class="money ? 'NSREmoney nsreChart col-md-6 text-center' : 'nsreChart col-md-6 text-center'" v-bind:style="'padding-top:10px;padding-bottom:10px; color:'+color">
      <span id="number" class="value super odometer hairline odometer-auto-theme"></span><br>

      <h2 class="text-default element-top-10 element-bottom-0 text-normal hairline" v-bind:style="'color:'+color" v-html="header"></h2>

      <h3 class="text-default element-top-0 element-bottom-10 text-normal hairline" v-bind:style="'color:'+color" v-html="subheader"></h3>
    </div>`;



    Vue.component('odometer-chart',{

              props:['value','header','subheader', 'color', 'money'],

        template:odometerHTML,

        watch:{

          value:function(){

               $(this.$el).find('#number').text(this.value);

                      }

        }
    });


R.forEach((n) => Vue.filter(n, window[n]), ['birthdayFormat', 'phoneFormat', 'phoneFormatMasked', 'zipFormat', 'allcaps','date1','date2','date3','date4','typeNames','spaceToDash','removeLeadingZeros'])


