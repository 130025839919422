function domProp(el,prop,value){

    $(el).prop(prop,value); 
}

function domHTML(el,value){

    $(el).html(value); 
}

function domValue(el,value){

    if(value){ 

        $(el).val(value);

    } else { 

        if($(el).val()){

          return $(el).val();

        } else {

          return null;
        }
    }
}


function domVis(el,vis){

    var check = function(){

        return $(el).is(':visible');
    }

    switch(vis){

        case 'show':
            $(el).show();
            break;

        case 'hide':
            $(el).hide();
            break;

        default:
            return check();
            break;
    }





   }

function domVisWithText(el,vis,text){

    $(el +' > p').html(text);

    switch(vis){

        case 'show':
            $(el).show();
            break;

        case 'hide':
            $(el).hide();
            break;

        default:

            break;
    }


    if((el == '#systemError' || el == '#validation') && vis == 'show'){

        $([document.documentElement, document.body]).animate({

            scrollTop: $(el).offset().top - 50

                }, 500);

            }

   }

function domToggle(el,bool){

  if(bool){

    $(el).toggle();
  }
}


function isElem(id){

  return ($("#"+id).length > 0) ? $("#"+id)[0] : false;
}



function addClass(el,classname){

  $(el).addClass(classname);

}


function removeClass(el,classname){

  $(el).removeClass(classname);
}


function addRedBox(obj){

    var elements = R.flatten(R.concat(obj.validation,obj.confirm));

    R.forEach(function(n){

        for(var prop in n){

            if(n[prop] == false){

                switch(prop){

                    case 'notificationPref':
                        addClass('#notificationPref-Email','invalid');
                        addClass('#notificationPref-Phone','invalid');
                        break;

                    case 'birthdate':
                        addClass('div.select-wrap','invalid');
                        break;

                    case 'groups':
                        $(`input[id*='${prop}']`).each(function (i, el) {
                            addClass(el,'invalid');
                        });
                        break;

                    case 'agreeConsent':
                        addClass('span.checkmark','invalid');
                        break;

                    default:
                        addClass(`#${prop}`,'invalid');
                        break;

                }
            }
        }

    }, elements);

}

function removeRedBox(obj){


       for(var prop in obj){

        switch(prop){

            case 'notificationPref':
                removeClass('#notificationPref-Email','invalid');
                removeClass('#notificationPref-Phone','invalid');
                break;

            case 'birthdate':
                removeClass('div.select-wrap','invalid');
                break;

            case 'groups':
                $(`input[id*='${prop}']`).each(function (i, el) {
                    removeClass(el,'invalid');
                });
                break;

            case 'agreeConsent':
                removeClass('span.checkmark','invalid');
                break;

            default:
                removeClass(`#${prop}`,'invalid');
                break;

        }
    }

        $(`input[id*='policy-']`).each(function (i, el) {
            removeClass(el,'invalid');
        });


}




function goTo(url){

    window.location.href = url;
}

function newTab(url, sessionKey = null,sessionValue = null){
    if(sessionKey && sessionValue){
        sessionStorage.setItem(sessionKey, sessionValue);
    }

    window.open(url, "_blank");
}


function getURLAction() {

    var results = new RegExp('[\?&#]action=([^&#]*)').exec(location.href);

    if (results !== undefined & results != null) {

             return results[1];
    }

}

function transferURL(data,defaultURL){

    var u = checkNull(userTypesURL[data.principal.userType]);
    var action = checkNull(actionTypesURL[getURLAction()]);

    if(u){

      goTo(u);

    } else if(action){

      goTo(action);


    } else {

      goTo(defaultURL);
    }

}

function submitState(){

  validation('hide')('');
  syserror('hide')('');

    processing('show');

}


var logIn = R.curry(domVis)('#loginMenu');
var logOut = R.curry(domVis)('#logoutMenu');

var validation = R.curry(domVisWithText)('#validation');
var syserror = R.curry(domVisWithText)('#systemError');
var processing = R.curry(domVis)('#processing');

var codeSection = R.curry(domVis)('#codeSection');
var signupSection = R.curry(domVis)('#signupSection');
var successSection = R.curry(domVis)('#successSection');
var signIn = R.curry(domVis)('#signin');
var signInWithNew = R.curry(domVis)('#signinWithNew');
var sendCode = R.curry(domVis)('#sendCode');

var enterHandler = function(){};


function enterKey(e){

    var key = e.which || e.keyCode;

    if (key === 13) { 

      enterHandler(e);

    }
}

document.addEventListener('keypress', enterKey);



function toggleSearch(e){

      var url = window.location.pathname; 
      var filename = url.substring(url.lastIndexOf('/')+1); console.log(filename);

      setTimeout(function(){

        if(e.checked && filename !== 'search-client.html'){

          window.location = 'search-client.html';
        }

        if(!e.checked && filename !== 'search-agent.html'){

          window.location = 'search-agent.html';
        }

      }, 200);

  }



